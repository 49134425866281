import { RouteRecordRaw } from 'vue-router';
import { OrdersRoutesEnum } from './OrdersRoutesEnum';
import { POSRoutesEnum } from '@/modules/pos/router/POSRoutesEnum';
import { AdminStatsRoutesEnum } from '@/modules/adminstats/router';

const OrdersPage  = () => import(
    /* webpackChunkName: "Orders-module" */ 
    '../pages/orders/OrdersPage.vue'
);

const OrderPage  = () => import(
    /* webpackChunkName: "Orders-module" */ 
    '../pages/order/OrderPage.vue'
);

const PosOrdersPage  = () => import(
    /* webpackChunkName: "Orders-module" */ 
    '../pages/orders/PosOrdersPage.vue'
);

const PosCustomerOrdersPage  = () => import(
    /* webpackChunkName: "Orders-module" */ 
    '../pages/orders/PosCustomerOrdersPage.vue'
);

const PosGraphs  = () => import(
    '../../pos/pages/POSGraphsPage.vue'
);

const PosAddServiceSelectType  = () => import(
    '../../pos/pages/POSAddServiceSelectTypePage.vue'
);

const PosCustomerOrderPage  = () => import(
    /* webpackChunkName: "Orders-module" */ 
    '../pages/order/PosCustomerOrderPage.vue'
);

const AdmsPosNewServicesPage  = () => import(
    /* webpackChunkName: "Adms-module" */ 
    '../../adminstats/pages/AdmsPosNewServices.vue'
);

const AdmsPosGeoServicesBuyedPage  = () => import(
    /* webpackChunkName: "Adms-module" */ 
    '../../adminstats/pages/AdmsPosGeoServicesBuyed.vue'
);

const AdmsCustomersGeoServicesBuyedPage  = () => import(
    '../../adminstats/pages/AdmsCustomersGeoServicesBuyed.vue'
);

const CanceledOrdersPage  = () => import(
    /* webpackChunkName: "Orders-module" */ 
    '../pages/orders/CanceledOrdersPage.vue'
);

const routes:  Array<RouteRecordRaw> = [
    {
        path: '/orders',
        name: OrdersRoutesEnum.ORDERS,
        component: OrdersPage,
        meta      : {
			tabTitle : "orders.list.tab_title"
		}
    },
    {
        path: '/pos-orders',
        name: POSRoutesEnum.POS_ORDERS,
        component: PosOrdersPage,
        meta      : {
			tabTitle : "orders.list.tab_title"
		}
    },
    {
        path: '/adms-pos-newservices',
        name: AdminStatsRoutesEnum.ADMS_POS_NEWSERVICES,
        component: AdmsPosNewServicesPage,
        meta      : {
			tabTitle : "orders.list.tab_title"
		}
    },
    {
        path: '/adms-pos-geoservicesbuyed',
        name: AdminStatsRoutesEnum.ADMS_POS_GEOBUYEDSERVICES,
        component: AdmsPosGeoServicesBuyedPage,
        meta      : {
			tabTitle : "orders.list.tab_title"
		}
    },
    {
        path: '/adms-customer-geoservicesbuyed',
        name: AdminStatsRoutesEnum.ADMS_CUSTOMER_GEOBUYEDSERVICES,
        component: AdmsCustomersGeoServicesBuyedPage,
        meta      : {
			tabTitle : "orders.list.tab_title"
		}
    },
    {
        path: '/pos-customer-orders',
        name: POSRoutesEnum.POS_CUSTOMER_ORDERS,
        component: PosCustomerOrdersPage,
        meta      : {
			tabTitle : "orders.list.tab_title"
		}
    },
    {
        path: '/pos-services-add-select-type',
        name: POSRoutesEnum.ADD_SERVICE_SELECT,
        component: PosAddServiceSelectType,
        meta      : {
			tabTitle : "pos.addservice.select_type"
		}
    },
    {
        path: '/pos-graphs',
        name: POSRoutesEnum.POS_GRAPHS,
        component: PosGraphs,
        meta      : {
			tabTitle : "orders.list.tab_title"
		}
    },
    {
        path: '/orders/:orderId',
        props: true,
        name: OrdersRoutesEnum.ORDER_DETAILS,
        component: OrderPage,
        meta      : {
			tabTitle : "orders.details.tab_title"
		}
    },
    {
        path: '/pos-customer-orders/:orderId',
        props: true,
        name: OrdersRoutesEnum.POS_CUSTOMER_ORDER_DETAILS,
        component: PosCustomerOrderPage,
        meta      : {
			tabTitle : "customer-orders.details.tab_title"
		}
    },
    {
        path: '/canceled-orders',
        props: true,
        name: OrdersRoutesEnum.CANCELED_ORDERS,
        component: CanceledOrdersPage,
        meta      : {
			tabTitle : "orders.list.tab_title"
		}
    },
];

export { routes };
export { OrdersRoutesEnum };