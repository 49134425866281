import { RouteRecordRaw } from 'vue-router';
import { AdminStatsRoutesEnum } from './AdminStatsRoutesEnum';

const AdminStatsMainPage = () => import(/* webpackChunkName: "Users-module" */ '../pages/AdminStatsMainPage.vue');

const routes: Array<RouteRecordRaw> = [
	{
		path: '/adminstats',
		name: AdminStatsRoutesEnum.MAIN,
		props: true,
		component: AdminStatsMainPage,
		meta: {
			tabTitle: "admin.adminstats_dashboard.title"
		}
	},
];

export { routes };
export { AdminStatsRoutesEnum };