import { RouteRecordRaw } from 'vue-router';
import { CustomersRoutesEnum } from './CustomersRoutesEnum';

const CustomersPage  = () => import(/* webpackChunkName: "Customers-module" */ '../pages/customers/CustomersPage.vue');

const routes:  Array<RouteRecordRaw> = [
    {
        path: '/customers',
        name: CustomersRoutesEnum.CUSTOMERS,
        component: CustomersPage,
        meta: {
			tabTitle: "customers.list.tab_title"
        }
    },
];

export { routes };
export { CustomersRoutesEnum };