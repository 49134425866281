
import { UserRoleEnum } from '@/model/enums/UserRoleEnum';
import { AuthRoutesEnum } from '@/modules/auth/router';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import { guards, tabTitle } from './guards';
import { MainRoutesEnum } from './MainRoutesEnum';

const MainTemplate = () => import(
  /* webpackChunkName: "main-template" */ 
  '@/templates/main-template/MainTemplate.vue'
);
const ProfilePage = () => import(
  /* webpackChunkName: "main-template" */ 
  '@/modules/app/pages/Profile/ProfilePage.vue'
);
const ProviderAreaPage = () => import(
  /* webpackChunkName: "main-template" */ 
  '@/modules/app/pages/Profile/ProviderAreaPage.vue'
);
const ChangePasswordPage = () => import(
  /* webpackChunkName: "main-template" */ 
  '@/modules/app/pages/Profile/ChangePasswordPage.vue'
);
const DashboardPage = () => import(
  /* webpackChunkName: "main-template" */ 
  '@/modules/app/pages/DashboardPage.vue'
);
const NotificationsPage = () => import(
  /* webpackChunkName: "main-template" */ 
  '@/modules/app/pages/Notifications/NotificationsPage.vue'
);

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*', // 404
    redirect: '/dashboard'
  },
  {
    path: '/',
    component: MainTemplate,
    name: MainRoutesEnum.MAIN_TEMPLATE,
    redirect: { name: MainRoutesEnum.DASHBOARD },
    children: [
      {
        path: '/dashboard',
        name: MainRoutesEnum.DASHBOARD,
        component: DashboardPage,
        props: true,
        meta: {
          tabTitle: 'dashboard.title'
        },
      },
      {
        path: '/notifications',
        name: MainRoutesEnum.NOTIFICATIONS,
        component: NotificationsPage,
        meta: {
          tabTitle: 'notifications.title'
        },
      },
      {
        path: '/profilo',
        name: AuthRoutesEnum.PROFILE,
        component: ProfilePage,
        props: true,
        meta: {
          tabTitle: 'profile.title',
          roles: [UserRoleEnum.SUPER_ADMIN]
        }
      },
      {
        path: '/provider/dati',
        name: AuthRoutesEnum.PROVIDER_AREA,
        component: ProviderAreaPage,
        props: true,
        meta: {
          tabTitle: 'provider.profile.title',
          roles: [UserRoleEnum.PROVIDER]
        }
      },
      {
        path: '/gestisci-password',
        name: AuthRoutesEnum.CHANGE_PASSWORD,
        component: ChangePasswordPage,
        props: true,
        meta: {
          tabTitle: 'profile.btn_change_password_label'
        }
      }
    ],

    meta: {
      requiresAuth: true
    },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

guards(router);
tabTitle(router);

export default router
