import { Plugin } from 'vue';

enum LocalStorageEnum {
    ACCESS_TOKEN = "ACCESS_TOKEN"
}

class LocalStorageSVC {

    get accessToken(): string {
        return this._get(LocalStorageEnum.ACCESS_TOKEN);
    }

    saveAccessToken(token: string, session: boolean = false) {
        this._save(LocalStorageEnum.ACCESS_TOKEN, token, session);
    }

    clearToken() {
        sessionStorage.removeItem(LocalStorageEnum.ACCESS_TOKEN);
        localStorage.removeItem(LocalStorageEnum.ACCESS_TOKEN);
    }

    private _save<T>(name: string, item: T, session: boolean = false) {
        if (session) {
            sessionStorage.setItem(name, JSON.stringify(item));
        } else {
            localStorage.setItem(name, JSON.stringify(item));
        }
    }

    private _get<T>(name: string) {
        const stringified = sessionStorage.getItem(name) || localStorage.getItem(name);

        return stringified
            ? JSON.parse(stringified) as T
            : null;
    }
}

declare module 'vue' {
    interface ComponentCustomProperties {
        readonly $localStorage: LocalStorageSVC;
    }
}

const LocalStoragePlugin: Plugin = {
    install(app) {
        console.debug("Installing local-storage plugin...");

        app.config.globalProperties.$localStorage = localStorageSVC;
    }
};

export const localStorageSVC = new LocalStorageSVC();

export default LocalStoragePlugin;