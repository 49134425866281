import { App } from 'vue';
import CurrencyText from './CurrencyText/CurrencyText.vue';
import DateFormatted from './DateFormatted/DateFormatted.vue';
import FloatLabel from './FloatLabel/FloatLabel.vue';
import ButtonIcon from './ButtonIcon/ButtonIcon.vue';
import InfoIcon from './InfoIcon/InfoIcon.vue';
import DataTable from './datatable/DataTable.vue';
import Column from './column/Column.vue';
import Sidebar from './sidebar/Sidebar.vue';
import VueCal from 'vue-cal';

export function registerGlobalComponents(app: App){
    app.component('FloatLabel', FloatLabel);
    app.component('DateFormatted', DateFormatted);
    app.component('CurrencyText', CurrencyText);
    app.component('ButtonIcon', ButtonIcon);
    app.component('InfoIcon', InfoIcon);
    app.component('DataTable', DataTable);
    app.component('Column', Column);
    app.component('Sidebar', Sidebar);
    app.component('VueCal', VueCal);
}