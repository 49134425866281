import { CalendarData } from '@/model/api/CalendarData';
import { PosServiceDayPLan } from '@/model/api/PosServiceDayPlan';
import { MutationTree } from 'vuex';
import { CalendarState } from './state';

//#region Provider mutations
interface ProviderMutations {
    setMonth(month: string): void;
    setMonthEvents(data: CalendarData[]): void;
}

const providerMutations: MutationTree<CalendarState> = {
    setMonth(state, month: string) {
        state.month = month;
    },

    setMonthEvents(state, data: CalendarData[]) {
        state.monthEvents = data;
    },
}
//#endregion

//#region Pos mutations
interface PosMutations {
    setPosMonth(month: string): void;
    setPosMonthEvents(data: CalendarData[]): void;

    setPosDate(date: string): void;
    setPosDayEvents(data: PosServiceDayPLan[]): void;
}

const posMutations: MutationTree<CalendarState> = {
    setPosMonth(state, month: string) {
        state.posMonth = month;
    },

    setPosMonthEvents(state, data: CalendarData[]) {
        state.posMonthEvents = data;
    },


    setPosDate(state, date: string) {
        state.posDay = date;
    },

    setPosDayEvents(state, data: PosServiceDayPLan[]) {
        state.posDayEvents = data;
    },
}
//#endregion

export interface Mutations
    extends ProviderMutations, PosMutations {

}

const mutations: MutationTree<CalendarState> = {
    ...providerMutations,
    ...posMutations,

}

export default mutations;