/**
 * NB:
 * È fondamentale che ciascun gruppo di rotte sia identificabile dal prefisso.
 * 
 * Es: le rotte degli utenti devono iniziare con "users",
 * sopratutto se si raggiungono tramite la tabella utenti.
 * Altrimenti al rientro su di essa vengono cancellati i filtri
 */
export enum UsersRoutesEnum {
    MAIN = 'users.main',

    PROVIDER_PREFIX = "providers",
    PROVIDER_LIST = 'providers.list',
    PROVIDER_EDIT = 'providers.edit',

    ADMIN_PREFIX = "admins",
    ADMIN_LIST = 'admins.list',
    ADMIN_EDIT = 'admins.edit',

    USER_PREFIX = "users",
    USER_LIST = 'users.list',
    USER_EDIT = 'users.edit'
}