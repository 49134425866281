import { GetterTree }       from "vuex";
import { createGetters }    from "@/store/common";
import { AppState }         from "@/store/types";
import { ProviderTechnicianState }        from "./state";
import { Appointment } from "@/model/api/appointment";

export interface Getters {
    month: string; 
    appointments: Appointment[];

    vueCalAppointments: any[];
    vueCalAvailabilities: any[];
    // directGetter: any;
    // computedGetter: any;
    // withParams(param:any): any;
}

const getters: GetterTree<ProviderTechnicianState, AppState> = {
    ...createGetters(
        "month",
        "appointments",
    ),

    vueCalAppointments({ appointments }) {
        return appointments?.map(x => ({
                start : x.date_at,
                end   : x.date_at,
                data  : x
            })
        );
    },
    vueCalAvailabilities({ availabilities }) {
        return availabilities?.map(x => ({
                start : x.date_at,
                end   : x.date_at,
                data  : x
            })
        );
    },


    // computedGetter(state) {
    //     return "computedGetter";
    // },

    // withParams: (state) => (param:any) => {
    //     return "withParams";
    // }
}

export default getters;