import { App, Plugin } from 'vue';

import { ModuleOptions } from '@/modules/common/ModuleOptions';

import { routes } from './router';
import { AuthStoreModule } from './store';


export const AuthModule: Plugin = {
    install(app: App<Element>, { store, router, parentRoute, storeName }: ModuleOptions) {
        if (parentRoute) {
            routes.forEach((route) => {
                router.addRoute(parentRoute, route);
            });
        } else {
            routes.forEach((route) => {
                router.addRoute(route);
            });
        }

        store.registerModule(
            storeName || 'auth',
            AuthStoreModule
        );
    }
};
