import { RouteRecordRaw } from 'vue-router';
import { CouponsRoutesEnum } from './CouponsRoutesEnum';

const CouponsPage  = () => import(
    /* webpackChunkName: "Coupons-module" */ 
    '../pages/coupons/CouponsPage.vue'
);

const IssuedCouponsPage  = () => import(
    /* webpackChunkName: "Coupons-module" */ 
    '../pages/coupons/IssuedCouponsPage.vue'
);

const CouponPage  = () => import(
    /* webpackChunkName: "Coupons-module" */ 
    '../pages/coupon/CouponPage.vue'
);

const AddCouponPage  = () => import(
    /* webpackChunkName: "Coupons-module" */ 
    '../pages/coupons/AddCouponPage.vue'
);


const routes:  Array<RouteRecordRaw> = [
    {
        path: '/coupons',
        name: CouponsRoutesEnum.COUPONS,
        component: CouponsPage,
        meta      : {
			tabTitle : "coupons.list.tab_title"
		}
    },
    {
        path: '/issued-coupons',
        name: CouponsRoutesEnum.ISSUED_COUPONS,
        component: IssuedCouponsPage,
        meta      : {
			tabTitle : "coupons.issued.list.tab_title"
		}
    },
    {
        path: '/coupons/:couponId',
        props: true,
        name: CouponsRoutesEnum.COUPON_DETAILS,
        component: CouponPage,
        meta      : {
			tabTitle : "coupon.details.tab_title"
		}
    },
    {
        path: '/add-coupon',
        props: true,
        name: CouponsRoutesEnum.ADD_COUPON,
        component: AddCouponPage,
        meta      : {
			tabTitle : "coupon.add.tab_title"
		}
    },];

export { routes };
export { CouponsRoutesEnum };