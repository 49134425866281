import { Appointment } from "@/model/api/appointment";

export interface ProviderTechnicianState {
  month          : string;
  appointments   : Appointment[];
  availabilities : Appointment[];
}

const state: ProviderTechnicianState = {
  month          : null,
  appointments   : [],
  availabilities : [],
};

export default state;