import { Module } from 'vuex'

import { CreateActionsHandler, CreateGettersHandler, CreateMutationsHandler } from '@/store/common';
import { ModulesEnum }  from '@/store/modules';
import { AppState }     from '@/store/types';

import actions, { Actions }         from './actions';
import getters, { Getters }         from './getters';
import mutations, { Mutations }     from './mutations';
import state, { AuthState }         from './state';

export const AuthStoreModule: Module<AuthState, AppState> = {
    namespaced: true,

    state,
    mutations   : { ...mutations },
    actions     : { ...actions  },
    getters     : { ...getters  },
};

export const authStore = {
    actions:    CreateActionsHandler<Actions, AuthState>(ModulesEnum.AUTH,      AuthStoreModule),
    mutations:  CreateMutationsHandler<Mutations, AuthState>(ModulesEnum.AUTH,  AuthStoreModule),
    getters:    CreateGettersHandler<Getters, AuthState>(ModulesEnum.AUTH,      AuthStoreModule)
}