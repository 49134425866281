/**
 * NB:
 * È fondamentale che ciascun gruppo di rotte sia identificabile dal prefisso.
 * 
 * Es: le rotte degli utenti devono iniziare con "users",
 * sopratutto se si raggiungono tramite la tabella utenti.
 * Altrimenti al rientro su di essa vengono cancellati i filtri
 */
export enum ServicesRoutesEnum{
    SERVICES_PREFIX     = "services",
    SERVICES_LIST       = 'services.list',
    SERVICES_EDIT       = 'services.edit',
    SERVICES_VISIBILITY = 'services.visibility',

    NETWORK_PREFIX = "network",
    NETWORK_LIST   = 'network.list',
    NETWORK_EDIT   = 'network.edit',

    SPONSORS_PREFIX = "sponsors",
    SPONSORS_LIST   = 'sponsors.list',
    SPONSORS_EDIT   = 'sponsors.edit',

    CATEGORIES_PREFIX = "service_categories",
    CATEGORIES_LIST   = 'service_categories.list',
    CATEGORIES_EDIT   = 'service_categories.edit'
}