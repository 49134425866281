import { RouteRecordRaw } from 'vue-router';
import { POSRoutesEnum } from './POSRoutesEnum';

const POSListPage = () => import(/* webpackChunkName: "POS-module" */ '../pages/POSListPage.vue');
const POSEditPage = () => import(/* webpackChunkName: "POS-module" */ '../pages/POSEditPage.vue');

const POSCategoryListPage = () => import(/* webpackChunkName: "POS-module" */ '../pages/POSCategoryListPage.vue');
const POSCategoryEditPage = () => import(/* webpackChunkName: "POS-module" */ '../pages/POSCategoryEditPage.vue');

const POSAreaPage = () => import(/* webpackChunkName: "POS-module" */ '../pages/POSAreaPage.vue');

const POSServicesListPage = () => import(/* webpackChunkName: "POS-services-module" */ '../pages/services/POSServicesListPage.vue');
const POSAddServicePage = () => import(/* webpackChunkName: "POS-services-module" */ '../pages/services/POSAddServicePage.vue');

const POSCustomerFeedbacksPage = () => import(/* webpackChunkName: "POS-services-module" */ '../pages/feedbacks/POSCustomerFeedbacksPage.vue');

const POSPortfolioListPage = () => import(/* webpackChunkName: "POS-portfolio-module" */ '../pages/portfolio/POSPortfolioListPage.vue');
const POSPortfolioEditPage = () => import(/* webpackChunkName: "POS-portfolio-module" */ '../pages/portfolio/POSPortfolioEditPage.vue');
const POSAddSingleServicePage = () => import(/* webpackChunkName: "POS-portfolio-module" */ '../pages/portfolio/POSAddSingleServicePage.vue');
const POSAddRecursiveServicePage = () => import(/* webpackChunkName: "POS-portfolio-module" */ '../pages/portfolio/POSAddRecursiveServicePage.vue');
const POSAddProviderServicePage = () => import(/* webpackChunkName: "POS-portfolio-module" */ '../pages/portfolio/POSAddProviderServicePage.vue');
const POSInvoicesPage = () => import(/* webpackChunkName: "POS-portfolio-module" */ '../pages/POSInvoicesPage.vue');

const routes: Array<RouteRecordRaw> = [
	{
		path: '/elenco-pos',
		name: POSRoutesEnum.LIST,
		props: true,
		component: POSListPage,
		meta: {
			tabTitle: "admin.pos.list.title"
		}
	},
	{
		path: '/dettaglio-pos/:posId',
		name: POSRoutesEnum.EDIT,
		props: true,
		component: POSEditPage,
		meta: {
			tabTitle: "admin.pos.detail.title"
		}
	},
	{
		path: '/tipologie-pos',
		name: POSRoutesEnum.CATEGORIES_LIST,
		props: true,
		component: POSCategoryListPage,
		meta: {
			tabTitle: "admin.pos_categories.list.title"
		}
	},
	{
		path: '/tipologie-pos/:categoryId',
		name: POSRoutesEnum.CATEGORIES_EDIT,
		props: true,
		component: POSCategoryEditPage,
		meta: {
			tabTitle: "admin.pos_categories.detail.title"
		}
	},
	{
		path: '/pos-area',
		name: POSRoutesEnum.PROFILE_AREA,
		props: true,
		component: POSAreaPage,
		meta: {
			tabTitle: "pos.profile.title"
		}
	},
	{
		path: '/pos/services',
		name: POSRoutesEnum.SERVICES_LIST,
		props: true,
		component: POSServicesListPage,
		meta: {
			tabTitle: "pos.services.list.title"
		}
	},
	{
		path: '/pos/feedbacks',
		name: POSRoutesEnum.POS_CUSTOMER_FEEDBACKS,
		props: true,
		component: POSCustomerFeedbacksPage,
		meta: {
			tabTitle: "pos.feedbacks.list.title"
		}
	},
	{
		path: '/pos/services/add/:step?',
		name: POSRoutesEnum.ADD_SERVICE,
		props: true,
		component: POSAddServicePage,
		meta: {
			tabTitle: "pos.services.add.title"
		}
	},
	{
		path: '/pos/services/edit/:id/:step?',
		name: POSRoutesEnum.EDIT_SERVICE,
		props: true,
		component: POSAddServicePage,
		meta: {
			tabTitle: "pos.services.add.title"
		}
	},
	{
		path: '/pos/portfolio',
		name: POSRoutesEnum.PORTFOLIO_LIST,
		props: true,
		component: POSPortfolioListPage,
		meta: {
			tabTitle: "pos.portfolio.list.title"
		}
	},
	{
		path: '/pos/portfolio/edit/:portfolioId',
		name: POSRoutesEnum.PORTFOLIO_EDIT,
		props: true,
		component: POSPortfolioEditPage,
		meta: {
			tabTitle: "pos.portfolio.edit.configure_service.title"
		}
	},
	{
		path: '/pos/portfolio/add-provider-service/:step?',
		name: POSRoutesEnum.ADD_PROVIDER_SERVICE,
		props: true,
		component: POSAddProviderServicePage,
		meta: {
			tabTitle: "pos.portfolio.add_provider_service.title"
		}
	},
	{
		path: '/pos/portfolio/add-single-service/:step?',
		name: POSRoutesEnum.ADD_SINGLE_SERVICE,
		props: true,
		component: POSAddSingleServicePage,
		meta: {
			tabTitle: "pos.portfolio.add_single_service.title"
		}
	},
	{
		path: '/pos/portfolio/add-recursive-service/:step?',
		name: POSRoutesEnum.ADD_RECURSIVE_SERVICE,
		props: true,
		component: POSAddRecursiveServicePage,
		meta: {
			tabTitle: "pos.portfolio.add_recursive_service.title"
		}
	},
	{
		path: '/pos/invoices',
		name: POSRoutesEnum.INVOICES,
		props: true,
		component: POSInvoicesPage,
		meta: {
			tabTitle: "pos.invoices.list.title"
		}
	},
];

export { routes };
export { POSRoutesEnum };