/**
 * NB:
 * È fondamentale che ciascun gruppo di rotte sia identificabile dal prefisso.
 * 
 * Es: le rotte degli utenti devono iniziare con "users",
 * sopratutto se si raggiungono tramite la tabella utenti.
 * Altrimenti al rientro su di essa vengono cancellati i filtri
 */
export enum TechniciansRoutesEnum {
    PREFIX           = 'technicians',
    TECHNICIAN_LIST  = 'technicians',
    TECHNICIANS_EDIT = 'technicians.edit',

    ADMIN_PREFIX             = 'admin_technicians',
    ADMIN_TECHNICIAN_LIST    = 'admin_technicians',
    ADMIN_TECHNICIAN_DETAILS = 'admin_technicians.details',
}