import { isForOfStatement } from "@babel/types";
import OverlayPanel from "primevue/overlaypanel";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  name: 'InfoIcon',
})
export default class InfoIcon extends Vue {

  @Prop({ default: null })
  icon: string;

  @Prop({ default: null })
  text: string;

  show(event) {
    (this.$refs.infoPanel as OverlayPanel).show(event);
  }

  hide() {
    (this.$refs.infoPanel as OverlayPanel).hide();
  }

  get textTranslated() {
    const tt = this.$t(this.text);
    if (!tt || tt == this.text) return null;

    return tt;
  }
}
