import store from '@/store';
import { Module } from 'vuex';
import { AppState } from './types';

/**
 * Create getter function for each property name passed
 * @param args :string[] -> array of property names
 * @returns 
 */
export function createGetters(...args: string[]) {
    const result: any = {};

    args.forEach(arg => {
        const getter = (state: any) => state[arg];
        result[arg] = getter;
    });

    return result;
}

/**
 * Create setter function for each property name passed
 * @param args :string[] -> array of property names 
 * @returns 
 */
export function createMutations(...args: string[]) {
    const result: any = {};

    args.forEach( (arg:string) => {
        const setter = (state: any, value: any) => state[arg] = value;
        result[`set${arg.capitalize()}`] = setter
    });

    return result;
}

export function CreateMutationsHandler<T, S = any>(PREFIX: string, store: Module<S, AppState>) {
    return new Proxy({}, StoreMutationProxyHandler(PREFIX, store.mutations)) as T;
}

export function CreateActionsHandler<T, S = any>(PREFIX: string, store: Module<S, AppState>) {
    return new Proxy({}, StoreActionProxyHandler(PREFIX, store.actions)) as T;
}

export function CreateGettersHandler<T, S = any>(PREFIX: string, store: Module<S, AppState>) {
    return new Proxy({}, StoreGetterProxyHandler(PREFIX, store?.getters)) as T;
}

const StoreMutationProxyHandler = (PREFIX: string, mutations: any) => {
    const mutationsNames = Object.getOwnPropertyNames(mutations);
    return {
        get: (target: any, key: any) => {
            return function (...rest: any[]) {
                return store.commit(PREFIX + "/" + key, ...rest);
            };
        },
        ownKeys() {
            return [...mutationsNames, "prototype"];
        },
        getPrototypeOf(target: any) {
            return target;
        }
    };
};

const StoreActionProxyHandler = (PREFIX: string, actions: any) => {
    const actionsNames = Object.getOwnPropertyNames(actions);
    return {
        get: (target: any, key: any) => {
            return function (...rest: any[]) {
                return store.dispatch(PREFIX + "/" + key, ...rest);
            };
        },
        ownKeys() {
            return [...actionsNames, "prototype"];
        },
        getPrototypeOf(target: any) {
            return target;
        }
    };
};

const StoreGetterProxyHandler = (PREFIX: string, getters: any) => {
    const gettersNames = Object.getOwnPropertyNames(getters);
    const proxy = {
        get: (target: any, key: any) => {
            // check se non esiste proprio il getter
            if (!store.getters.hasOwnProperty(PREFIX + "/" + key)) {
                throw `getter "${PREFIX}/${key}" is not registered in the store... maybe the store module is not namespaced?`
            }
            const storeGetter = store.getters[PREFIX + "/" + key];
            const call = function (...args: any[]) {
                if (storeGetter instanceof Function) return storeGetter(...args);
                return storeGetter; // se ha dei parametri perché è una funzione                    
            };
            if (key && !key.startsWith('get')) return call();
            return call;
        },
        ownKeys() {
            return [...gettersNames, "prototype"];
        },
        getPrototypeOf(target: any) {
            return target;
        }
    };
    return proxy;
};