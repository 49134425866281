import { UserRoleEnum } from '@/model/enums/UserRoleEnum';
import { RouteRecordRaw } from 'vue-router';
import { TechniciansRoutesEnum } from './TechniciansRoutesEnum';

const TechniciansPage = () => import(
    /* webpackChunkName: "Technicians-module" */
    '../pages/technicians/TechniciansPage.vue');

const TechnicianPage = () => import(
    /* webpackChunkName: "Technicians-module" */
    '../pages/technician/TechnicianPage.vue');

const AdminTechniciansPage = () => import(
    /* webpackChunkName: "Admin-Technicians-module" */
    '../pages/admin-technicians/AdminTechniciansPage.vue');

const AdminTechnicianDetailsPage = () => import(
    /* webpackChunkName: "Admin-Technicians-module" */
    '../pages/admin-technician-details/AdminTechnicianDetailsPage.vue');

const routes: Array<RouteRecordRaw> = [
    {
        path: '/admin/tecnici',
        name: TechniciansRoutesEnum.ADMIN_TECHNICIAN_LIST,
        component: AdminTechniciansPage,
        props: true,
        meta: {
            roles: [
                UserRoleEnum.PROVIDER, 
                UserRoleEnum.SUPER_ADMIN
            ],
            tabTitle: 'admin.technicians.list.title'
        }
    },
    {   
        path: '/admin/tecnici/:technicianId',
        name: TechniciansRoutesEnum.ADMIN_TECHNICIAN_DETAILS,
        component: AdminTechnicianDetailsPage,
        props: true,
        meta: {
            roles: [
                UserRoleEnum.PROVIDER, 
                UserRoleEnum.SUPER_ADMIN
            ],
            tabTitle: 'admin.technicians.list.title'
        }
    },
    {
        path: '/tecnici/:providerId?',
        name: TechniciansRoutesEnum.TECHNICIAN_LIST,
        component: TechniciansPage,
        props: true,
        meta: {
            roles: [UserRoleEnum.PROVIDER, UserRoleEnum.SUPER_ADMIN],
            tabTitle: 'provider.technicians.list.title'
        }
    },
    {
        path: '/dettaglio-tecnico/:technicianId/:providerId?',
        name: TechniciansRoutesEnum.TECHNICIANS_EDIT,
        props: true,
        component: TechnicianPage,
        meta: {
            roles: [UserRoleEnum.PROVIDER, UserRoleEnum.SUPER_ADMIN],
            tabTitle: 'provider.technicians.detail.title'
        }
    },
];

export { routes };
export { TechniciansRoutesEnum };