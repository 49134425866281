import { Plugin } from 'vue';
import { authStore }          from '@/modules/auth/store';
import { UserRoleEnum } from '@/model/enums/UserRoleEnum';

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        /**
         * Indica se è in corso una request bloccante
         */
        requestPending: boolean;

        /**
         * Gestisce una chiamata asincrona "importante"  
         * 
         * Setta requestPending a true prima della chiamata
         * e lo resetta al termine, a prescindere dal risultato.
         * 
         * E' previsto un messaggio d'errore se necessario
         */
        readonly $waitFor: (request: () => Promise<any>, errorMessage?: string) => Promise<void>;

        /**
         * Gestisce i tabindex comuni come per esempio gli autocomplete (toglie il focus da elementi non necessari)
         */
        readonly $fixCommonTabIndex: () => Promise<void>;
    }
}

const UtilityPlugin: Plugin = {
    install(app) {
        console.debug("Installing Utility plugin...");

        app.mixin({
            data() {
                return {
                    requestPending: false
                }
            },

            computed: {
                isSuperAdmin() {
                    return authStore.getters.me.role == UserRoleEnum.SUPER_ADMIN;
                },
            
                isProvider() {
                    return authStore.getters.me.role == UserRoleEnum.PROVIDER;
                },
            
                isPOS() {
                    return authStore.getters.me.role == UserRoleEnum.POS;
                },

                isTechnician() {
                    return authStore.getters.me.role == UserRoleEnum.PRTEC;
                },
            }
        })

        app.config.globalProperties.$waitFor =
            async function (request: () => Promise<any>, errorMessage: string = "Qualcosa è andato storto") {
                try {
                    this.requestPending = true;

                    await request();

                } catch (error: any) {
                    console.error(error);

                    if (error?.status === 404 && this.$route?.name?.includes('detail')) {
                        await this.$router.replace({
                            name: this.$route?.name.replace('detail', 'list')
                        });
                    }

                    if (error?.status === 422) {
                        const fieldsInError = Object.keys(error.data.errors || []);

                        fieldsInError.forEach(k => {
                            this.$errorMessage(error.data.errors[k][0], null, 5e3);
                        });

                        if (!fieldsInError.length && error.data.message) {
                            this.$errorMessage(error.data.message);
                        }
                    } else {
                        this.$errorMessage(errorMessage);
                    }
                } finally {
                    this.requestPending = false;
                }
            };

        app.config.globalProperties.$fixCommonTabIndex =
            function () {
                document.querySelectorAll('button.p-autocomplete-dropdown').forEach(e => e.setAttribute('tabindex', '-1'));
            };
    }
};

export default UtilityPlugin;