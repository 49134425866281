import { User } from "@/model/api/User";
import { UserRoleEnum } from "@/model/enums/UserRoleEnum";
import { createGetters } from "@/store/common";
import { AppState } from "@/store/types";
import { GetterTree } from "vuex";
import { AuthState } from "./state";

export interface Getters {
    me: User;
    userName: string;
    userRole: UserRoleEnum;
    meAvatarUrl: string;
    appProfile: any;
}

const getters: GetterTree<AuthState, AppState> = {
    ...createGetters(
        "me", "appProfile"
    ),

    userName({ me }) {
        return me ? `${me.name} ${me.surname}` : null;
    },

    userRole({ me }) {
        return me?.role;
    },
    
}

export default getters;