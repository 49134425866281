import { IBaseApiModel } from '@/model/common/BaseApiModel';
import { BaseApiFilter } from '@/model/filters/BaseApiFilter';
import { PaginatedResponse } from '@/model/api/PaginatedResponse';
import axios, { CancelTokenSource } from 'axios';
import { HttpService } from './http.service';

export abstract class CRUD<T extends IBaseApiModel, F extends BaseApiFilter> extends HttpService {

    protected readonly endPoint: string;

    public index(params?: F): Promise<PaginatedResponse<T>> {
        if (params.filters) {
            params.filters = this.getFilterParam(params.filters);
        }
        this.source = axios.CancelToken.source();
        const cancelToken = this.source.token;
        return this.get<PaginatedResponse<T>>(`${this.endPoint}`, { params, cancelToken });
    }

    public getById(id: number): Promise<T> {
        return this.get<T>(`${this.endPoint}/${id}`);
    }

    public getAll(params?: F): Promise<T[]> {
        if (params?.filters) {
            params.filters = this.getFilterParam(params.filters);
        }
        this.source = axios.CancelToken.source();
        const cancelToken = this.source.token;
        return this.get<T[]>(`${this.endPoint}`, { params, cancelToken });
    }

    public create(item: T): Promise<T> {
        return this.post<T>(`${this.endPoint}`, item);
    }

    public upsertAll(item: T[]): Promise<T[]> {
        return this.put<T[]>(`${this.endPoint}`, item);
    }

    public update(item: T): Promise<T> {
        return this.put<T>(`${this.endPoint}/${item.id}`, item);
    }
    public updatePatch(item: T): Promise<T> {
        return this.patch<T>(`${this.endPoint}/${item.id}`, item);
    }

    public remove(item: T): Promise<T> {
        return this.delete<T>(`${this.endPoint}/${item.id}`, item);
    }

    public removeMany(ids: number[]): Promise<T> {
        return this.delete<T>(`${this.endPoint}`, ids);
    }

    protected getFilterParam(filtersParam) {
        const filters = { ...filtersParam };
        if (filters) {
            Object.keys(filters).forEach(k => {
                const v = filters[k].value;
                const n = filters[k].nullable;

                if ((Array.isArray(v) && v.length === 0) || ((!v && v !== false) && !n)) {
                    delete filters[k];
                }
            });
        }
        return filters || null;
    }
}