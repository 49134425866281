import i18n from "@/i18n";
import { AuthRoutesEnum } from "@/modules/auth/router";
import { authStore } from "@/modules/auth/store";
import { configuration } from "@plugins/Config-plugin";
import { localStorageSVC } from "@plugins/LocalStorage-plugin";
import { Router } from "vue-router";
import { MainRoutesEnum } from "./MainRoutesEnum";

export function guards(router: Router) {
    router.beforeEach((to, from, next) => {
        // check meta route
        if (to.matched.some(it => it.meta.requiresAuth)) {
            // se non loggato torna alla login
            if (!localStorageSVC.accessToken) {
                next({ name: AuthRoutesEnum.SIGN_IN });
                return;
            }
        }
        if (to.matched.some(it => it.meta.requiresGuest)) {
            // se loggato va alla home
            if (localStorageSVC.accessToken) {
                next({ name: MainRoutesEnum.DASHBOARD });
                return;
            }
        }

        const roles = to.meta?.roles as Array<string>;
        if (roles?.length > 0) {
            const me = authStore.getters.me;
            if (!roles.includes(me.role)) {
                next({ name: MainRoutesEnum.DASHBOARD });
                return;
            }
        }

        next();
    });
}

export function tabTitle(router: Router) {
    router.beforeEach((to, from, next) => {
        // set tab title 
        if (to.matched && to.matched.length > 0) {
            const tabTitle = to.meta.tabTitle ? i18n.global.t(to.meta.tabTitle as string) : '';
            document.title = `${configuration.appTitle} - ${tabTitle}`;
        }

        next();
    });
}