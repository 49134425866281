import { Notification } from '@/model/api/Notification';
import { CRUD } from './base/crud';

class NotificationsService extends CRUD<Notification, any> {
    readonly endPoint = `notifications`;

    public fetchNotifications(unread: string) {
        return this.get<Notification[]>(`${this.endPoint}?unread=${unread}`);
    }

    public getNotificationsCount(unread: string) {
        return this.get<Notification[]>(`${this.endPoint}/count?unread=${unread}`);
    }

    public setReadAll() {
        return this.post<any>(`${this.endPoint}/read-all`);
    }

    public setRead(notificationId: number) {
        return this.post<any>(`${this.endPoint}/read/${notificationId}`);
    }
}

export const notificationsService = new NotificationsService();