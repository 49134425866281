import { AuthRoutesEnum } from '@/modules/auth/router';
import { ProviderTechnicianRoutesEnum } from '@/modules/provider-technician/router';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import { guards, tabTitle } from './guards';
import { MainRoutesEnum } from './MainRoutesEnum';

const MobileTemplate = () => import(
  /* webpackChunkName: "mobile-template" */ 
  '@/templates/mobile-template/MobileTemplate.vue'
);
const ProfileMobilePage = () => import(
  /* webpackChunkName: "profile" */ 
  '@/modules/app/pages/ProfileMobile/ProfileMobilePage.vue'
);
const NotificationsPage = () => import(
  /* webpackChunkName: "notifications" */ 
  '@/modules/app/pages/Notifications/NotificationsPage.vue'
);
const ChangePasswordMobilePage = () => import(
  /* webpackChunkName: "change_pwd" */ 
  '@/modules/app/pages/ProfileMobile/ChangePasswordMobilePage.vue'
);

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*', // 404
    redirect: '/appointments'
  },
  {
    path: '/:pathMatch(.*)*', // 404
    name: MainRoutesEnum.DASHBOARD,
    redirect: '/appointments'
  },
  {
    path: '/',
    component: MobileTemplate,
    name: MainRoutesEnum.MAIN_TEMPLATE,

    children: [
      {
        path: '/profilo',
        name: AuthRoutesEnum.PROFILE,
        component: ProfileMobilePage,
        meta: {
          tabTitle: 'profile.title',
        }
      },
      {
        path: '/notifications',
        name: MainRoutesEnum.NOTIFICATIONS,
        component: NotificationsPage,
        meta: {
          tabTitle: 'mobile.notifications.title',
        }
      },
      {
        path: '/gestisci-password',
        name: AuthRoutesEnum.CHANGE_PASSWORD,
        component: ChangePasswordMobilePage,
        props: true,
        meta: {
          tabTitle: 'profile.btn_change_password_label',
        }
      }
    ],

    redirect: { 
      name: ProviderTechnicianRoutesEnum.APPOINTMENTS 
    },

    meta: {
      requiresAuth: true
    },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

guards(router);
tabTitle(router);

export default router
