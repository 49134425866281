import { CalendarData } from "@/model/api/CalendarData";
import { PosServiceDayPLan } from "@/model/api/PosServiceDayPlan";

interface ProviderCalendarState {
  /**
   * Current month
   * String format : YYYY-MM
   */
  month       : string;
  monthEvents : CalendarData[];
}
interface PosCalendarState {
  /**
   * Current month
   * String format : YYYY-MM
   */
  posMonth       : string;
  posMonthEvents : CalendarData[];

  /**
   * Current day
   */
  posDay: string;
  posDayEvents: PosServiceDayPLan[];
}
export interface CalendarState
  extends ProviderCalendarState, PosCalendarState {

}

const state: CalendarState = {
  // Provider - Month/Day
  month       : null,
  monthEvents : null,

  // POS - Month
  posMonth       : null,
  posMonthEvents : null,

  // POS - Day
  posDay: null,
  posDayEvents: null,
};

export default state;