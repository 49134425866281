import moment from 'moment';
import i18n from "@/i18n";

/**
 * Filter an array of object, looking for match to "query" in some fields
 */
export function arrayFilter(data: any[], query: string, fields: string[] = ['name', 'description']) {
    if (!query || query.trim() === '') {
        return data;
    }

    const txt = query.toLowerCase().trim();

    const result = data.filter(x => {
        let match = false;

        fields.forEach((field) => {
            const value = (x[field] as string)?.toLowerCase().trim();
            if (value?.includes(txt)) {
                match = true;
            }
        });

        return match;
    });

    return result;
}

/**
 * Creates a debounced function that delays invoking func
 * @param timeoutid previous execution result
 * @param action func to invoke
 * @param timeout time (ms) default = 300
 */
export function debounce(timeoutid: number, action: Function, timeout: number = 300) {
    if (timeoutid) clearTimeout(timeoutid);
    return setTimeout(action, timeout);
}


export class Deferred<T> {
    resolve: Function;
    reject: Function;
    promise: Promise<T>;

    constructor() {
        this.promise = new Promise<T>((res, rej) => {
            this.resolve = res;
            this.reject = rej;
        });
    }
}

export function localizeMe(): Promise<GeolocationPosition> {
    if (navigator.geolocation) {
        const options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
        };
        return new Promise(function (resolve, reject) {
            navigator.geolocation.getCurrentPosition(resolve, reject, options);
        });
    } else {
        throw Error("Browser doesn't support geolocation");
    }
}


/**
 * Check compexity of password
 * @param password 
 * @param length 
 */
export function checkComplexityPassword(password: string, length: number = 8) {
    //?! -> Not required  ?= required
    const r = new RegExp(`(?!=.*d)(?!=.*[a-z])(?!=.*[A-Z])(?=.*[!@#$%&*()]).{${length},}`);
    return r.test(password)
}

/**
 * Convert a file in Base64 Format
 * @param file 
 */
export function toBase64(file: File) {
    return new Promise<string>((ok) => {
        const reader = new FileReader();

        reader.onload = (readerEvt) => {
            const binaryString = readerEvt.target.result;
            ok(btoa(binaryString as string));
        };

        reader.readAsBinaryString(file);
    });
}

/**
 * Copy to clipboard the text passed by parameter
 * @param textToCopy 
 */
export function copyToClipboard(textToCopy: string) {
    const el = document.createElement('textarea');
    el.setAttribute('readonly', '');

    el.style.position = 'absolute';
    el.style.left = '-9999px';

    el.value = textToCopy;

    document.body.appendChild(el);

    el.select();

    document.execCommand('copy');
    document.body.removeChild(el);
}


export function fileDownload(fileName: string, fileData: string, type: string) {
    const file = new Blob([fileData], { type });
    if ((window.navigator as any).msSaveOrOpenBlob)
        // IE10+
        (window.navigator as any).msSaveOrOpenBlob(fileName);
    else {
        // Others
        const a = document.createElement("a"),
            url = URL.createObjectURL(file);
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        setTimeout(function () {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        }, 0);
    }
}

/**
 * Print a DOM element selected by ID
 * @param selectorId --> example: "#main"
 */
export function printElement(selectorId: string) {
    const tmp = document.createDocumentFragment();

    const printme = document.getElementById(selectorId).cloneNode(true);

    while (document.body.firstChild) {
        // move elements into the temporary space
        tmp.appendChild(document.body.firstChild);
    }

    // put the cloned printable thing back, and print
    document.body.appendChild(printme);
    window.print();

    while (document.body.firstChild) {
        // empty the body again (remove the clone)
        document.body.removeChild(document.body.firstChild);
    }

    // re-add the temporary fragment back into the page, restoring initial state
    document.body.appendChild(tmp);
}

/**
 * Download a DOM "canvas" element as *.png file 
 * @param querySelector selector of canvas element 
 * - id -> #qr-code
 * - css selector -> canvas.qr-code
 * @param filename output file name
 */
export function downloadCanvas(querySelector: string, filename: string) {
    const canvasEl: HTMLCanvasElement = document.querySelector(querySelector)
    const url = canvasEl.toDataURL("image/png")

    const a = document.createElement('a')
    a.style.cssText = 'display: none'
    a.href = url
    a.target = '_blank'
    a.download = filename
    document.body.appendChild(a)

    a.click()

    document.body.removeChild(a);
}

/**
 * Download a DOM "svg" element as *.svg file
 * @param idSelector  selector of svg element 
 * - id -> #qr-code
 * - css selector -> svg.qr-code
 * @param filename output file name
 */
export function downloadSVG(idSelector: string, filename: string) {
    const svgEL: Node = document.getElementById(idSelector);

    const serializer = new XMLSerializer();
    let source = serializer.serializeToString(svgEL);

    //add name spaces.
    if (!source.match(/^<svg[^>]+xmlns="http:\/\/www\.w3\.org\/2000\/svg"/)) {
        source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
    }
    if (!source.match(/^<svg[^>]+"http:\/\/www\.w3\.org\/1999\/xlink"/)) {
        source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
    }

    //add xml declaration
    source = '<?xml version="1.0" standalone="no"?>\r\n' + source;

    //convert svg source to URI data scheme.
    const url = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(source);

    const a = document.createElement('a')
    a.style.cssText = 'display: none'
    a.href = url
    a.target = '_blank'
    a.download = filename

    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a);
}

/**
 * To add animation event listener
 * @param element 
 * @param type 
 * @param callback 
 */
export function prefixedEvent(element: Element, type: any, callback: any) {
    const pfx = ["webkit", "moz", "MS", "o", ""];
    for (let p = 0; p < pfx.length; p++) {
        if (!pfx[p]) type = type.toLowerCase();
        element.addEventListener(pfx[p] + type, callback, false);
    }
}

/**
 * To remove animation event listener
 * @param element 
 * @param type 
 * @param callback 
 */
export function removePrefixedEvent(element: any, type: any, callback: any) {
    const pfx = ["webkit", "moz", "MS", "o", ""];
    for (let p = 0; p < pfx.length; p++) {
        if (!pfx[p]) type = type.toLowerCase();
        element.removeEventListener(pfx[p] + type, callback);
    }
}

/**
 * Collection of regex
 */
export const rxs = {
    EMAIL: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/,
    DATE_TIME: /[012]?[0-9]\D[0-9]{2}/,
    LETTER_AND_SPACES: /[a-zA-Z\s]+/
}

/**
 * Lazy loader for script 
 * @param src 
 */
export function loadScript(src: string) {
    const s = document.createElement('script');
    s.type = "text/javascript";
    s.async = true;
    s.src = src;

    return new Promise((resolve) => {
        s.addEventListener('load', (evt) => {
            console.debug("Evento load");
            resolve(evt);
        });

        const head = document.getElementsByTagName('head')[0];
        head.appendChild(s);
    });

}

/**
 * OnDrop event handler, return a single file if present.
 * @param event OnDrop event 
 */
export function onDropSingleFile(event: DragEvent) {
    if (event.dataTransfer.items) {
        const item = event.dataTransfer.items[0];
        if (item.kind === 'file') {
            return item.getAsFile();
        }

    } else {
        return event.dataTransfer.files[0];
    }
}

function isTouchDevice() {
    let hasTouchScreen = false;

    if ("maxTouchPoints" in navigator) {
        hasTouchScreen = navigator.maxTouchPoints > 0;
    } else if ("msMaxTouchPoints" in navigator) {
        hasTouchScreen = (navigator as any).msMaxTouchPoints > 0;
    } else {
        const mQ = window.matchMedia && matchMedia("(pointer:coarse)");
        if (mQ && mQ.media === "(pointer:coarse)") {
            hasTouchScreen = !!mQ.matches;
        } else if ('orientation' in window) {
            hasTouchScreen = true; // deprecated, but good fallback
        } else {
            // Only as a last resort, fall back to user agent sniffing
            const UA = (navigator as any).userAgent;
            hasTouchScreen = (
                /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
                /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
            );
        }
    }

    return hasTouchScreen;
}

export function sumVat(value: number, vatPerc: number) {
    if (value > 0) {
        return value * (100 + vatPerc) / 100
    }
    return 0;
}

export function generateTimeSlots(start: string, end: string, minutesSlot: number, filterGreaterStart = false) {
    const slots = [];

    const formattedStart = moment(start, 'HH:mm').format('HH:mm');
    const formattedEnd = moment(end, 'HH:mm').format('HH:mm');
    let lastSlot = moment(start, 'HH:mm').format('HH:mm');
    
    while (lastSlot <= formattedEnd && lastSlot >= formattedStart) {
        slots.push(lastSlot);
        lastSlot = moment(lastSlot, 'HH:mm').add(minutesSlot, 'minute').format('HH:mm');
    }

    if (filterGreaterStart) {
        return slots.filter(s => s > start);
    }

    return slots;
}

export function generateDates(start: any, end: any, amout: number, unit: moment.unitOfTime.DurationConstructor): moment.Moment[] {
    const slots = [];

    let lastSlot = moment(start);
    while (lastSlot.isSameOrBefore(end)) {
        slots.push(lastSlot);        
        lastSlot = lastSlot.clone().add(amout, 'day');
    }

    return slots;
}

export function shortTime(time: string) {
    if (!time) return "";
    return time.substr(0, 5);
}

export function createSequence(length: number, step: number = 0) {
    return Array.from(
        { length },
        (_, i) => i + step
    )
}

export function createSequenceFromTo(from: number, to: number, step: number = 1) {
    const length = (to - from) / step + 1;

    return Array.from(
        { length },
        (_, i): number => (i * step) + from
    )
}

export function minMax(items) {
    return items.reduce((acc, val) => {
        acc[0] = ( acc[0] === undefined || val < acc[0] ) ? val : acc[0]
        acc[1] = ( acc[1] === undefined || val > acc[1] ) ? val : acc[1]
        return acc;
    }, []);
}


export function isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
        .test(navigator.userAgent);
}


export function convertMonthNames(month) {
      
    let mname = "";
    
    switch ( Math.trunc(month) ) 
    {
      case 1: 
        mname = i18n.global.t("generic.months.jan"); 
        break;
      case 2: 
        mname = i18n.global.t("generic.months.feb"); 
        break;
      case 3: 
        mname = i18n.global.t("generic.months.mar"); 
        break;
      case 4: 
        mname = i18n.global.t("generic.months.apr"); 
        break;
      case 5: 
        mname = i18n.global.t("generic.months.may"); 
        break;
      case 6: 
        mname = i18n.global.t("generic.months.june"); 
        break;
      case 7: 
        mname = i18n.global.t("generic.months.july"); 
        break;
      case 8: 
        mname = i18n.global.t("generic.months.aug"); 
        break;
      case 9: 
        mname = i18n.global.t("generic.months.sept"); 
        break;
      case 10: 
        mname = i18n.global.t("generic.months.oct"); 
        break;
      case 11: 
        mname = i18n.global.t("generic.months.nov"); 
        break;
      case 12: 
        mname = i18n.global.t("generic.months.dec"); 
        break;
      default: 
        mname = 'n.d.'; 
        break;
    }

    return mname;
}
