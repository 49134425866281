import 'reflect-metadata';
import "@/utils/collections";

//import { Loader } from "@googlemaps/js-api-loader";
import { createApp, Plugin } from 'vue'

// BOOTSTRAP COMPONENT
import MainRouter from './MainRouter.vue';

// STYLES
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

// CUSTOM SCSS
import '@/assets/theme/theme.scss';
import 'vue-cal/dist/vuecal.css';
import 'vue-cal/dist/i18n/it.js'

// CUSTOM PLUGINS
import {
    ConfigPlugin,
    LocalstoragePlugin,
    MessagesPlugin,
    UtilityPlugin
} from '@/plugins';

// VUE I18N https://vue-i18n.intlify.dev/
import i18n from './i18n'

// VUE ROUTER https://next.router.vuejs.org/
import router from './router'
import { MainRoutesEnum }    from '@/router/MainRoutesEnum';

import mobileRouter from './router/mobile-router'

function getRouter() {
    return isMobile() ? mobileRouter : router;
}

// VUE STORE https://next.vuex.vuejs.org/
import store, { key_store } from './store'

// EXTERNAL UI LIBRARIES
import { initPrimeVue }     from '@/primevue';
import { initFontawesome }  from '@/fontawesome';

// VEE-VALIDATE
import '@/vee-validate';
setLocale('it');

// MODULES
import { ModulesEnum }              from './store/modules';
import { ModuleOptions }            from '@/modules/common/ModuleOptions';
import { AuthModule }               from '@/modules/auth';
import { ServicesModule }           from '@/modules/services';
import { UsersModule }              from '@/modules/users';
import { AdminStatsModule }         from '@/modules/adminstats';
import { TechniciansModule }        from '@/modules/technicians';
import { CandidacyModule }          from '@/modules/candidacies';
import { CalendarModule }           from '@/modules/calendar';
import { POSModule }                from '@/modules/pos';
import { OrdersModule }             from '@/modules/orders';
import { CustomersModule }          from '@/modules/customers';
import { ProviderTechnicianModule } from '@/modules/provider-technician';

// GLOBAL COMPONENTS
import { registerGlobalComponents } from '@/components/globalComponents';

// LOCALSTORAGE SERVICE
import { localStorageSVC } from '@plugins/LocalStorage-plugin';

const app = createApp(MainRouter);

const modules: { module: Plugin, options: ModuleOptions }[] = isMobile() 
    ? /* Mobile routes */ [
        { 
            module: AuthModule,        
            options: { storeName:    ModulesEnum.AUTH, } 
        },
        { 
            module: ProviderTechnicianModule,        
            options: { 
                parentRoute:  MainRoutesEnum.MAIN_TEMPLATE,
                storeName:    ModulesEnum.PROV_TECH, 
            } 
        },
    ] 
    : /* Desktop routes */ [
        { module: AuthModule,        options: { storeName:    ModulesEnum.AUTH, } },
        { module: ServicesModule,    options: { parentRoute:  MainRoutesEnum.MAIN_TEMPLATE, storeName: ModulesEnum.SERVICES  },  },
        { module: UsersModule,       options: { parentRoute:  MainRoutesEnum.MAIN_TEMPLATE, storeName: ModulesEnum.USERS     },  },
        { module: AdminStatsModule,  options: { parentRoute:  MainRoutesEnum.MAIN_TEMPLATE, storeName: ModulesEnum.ADMIN_STATS    },  },
        { module: POSModule,         options: { parentRoute:  MainRoutesEnum.MAIN_TEMPLATE, storeName: ModulesEnum.POS       },  },
        { module: CandidacyModule,   options: { parentRoute:  MainRoutesEnum.MAIN_TEMPLATE, storeName: ModulesEnum.CANDIDACY },  },
        { module: TechniciansModule, options: { parentRoute:  MainRoutesEnum.MAIN_TEMPLATE },  },
        { module: CalendarModule,    options: { parentRoute:  MainRoutesEnum.MAIN_TEMPLATE, storeName: ModulesEnum.CALENDAR },  },
        { module: OrdersModule,      options: { parentRoute:  MainRoutesEnum.MAIN_TEMPLATE },  },
        { module: CustomersModule,   options: { parentRoute:  MainRoutesEnum.MAIN_TEMPLATE },  },
        { module: CouponsModule,     options: { parentRoute:  MainRoutesEnum.MAIN_TEMPLATE },  },
        { module: ProviderTechnicianModule,    options: { parentRoute:  MainRoutesEnum.MAIN_TEMPLATE, storeName: ModulesEnum.PROV_TECH },  },
    ];

modules.forEach(({ module, options }) => {
    options.router = getRouter();
    options.store = store;
    app.use(module, options);
});

app.use(i18n);
app.use(store, key_store);

app.use(ConfigPlugin);
app.use(LocalstoragePlugin);
app.use(MessagesPlugin);
app.use(UtilityPlugin);

initPrimeVue(app);
initFontawesome(app);

registerGlobalComponents(app);

// Adding lifecycle hooks
import { Vue } from 'vue-class-component'
import { isMobile } from './utils/utils';
import { UserRoleEnum } from './model/enums/UserRoleEnum';
import { CouponsModule } from './modules/coupons';
import { setLocale } from '@vee-validate/i18n';

Vue.registerHooks([
    'beforeRouteEnter',
    'beforeRouteLeave',
    'beforeRouteUpdate'
]);

/**
 * Fetch user details and check if user is authorized
 * to use the application.
 * If It is a provider technician and it's not in mobile context
 * clear token and reload the page.
 */
async function initUser() {
    if (localStorageSVC.accessToken) {
        await store.dispatch(`${ModulesEnum.AUTH}/getMe`)

        const user = store.getters[`${ModulesEnum.AUTH}/me`];

        // if (!isMobile() && user.role === UserRoleEnum.PRTEC) {
        //     localStorageSVC.clearToken();
        //     location.reload();
        // }
        store.dispatch('updateNotificationCount');
    }
}

const init = Promise.all([
    initUser()
]);

init.then(() => {
    app.use(getRouter());
    app.mount('#app')
});


