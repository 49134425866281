/**
 * NB:
 * È fondamentale che ciascun gruppo di rotte sia identificabile dal prefisso.
 * 
 * Es: le rotte degli utenti devono iniziare con "users",
 * sopratutto se si raggiungono tramite la tabella utenti.
 * Altrimenti al rientro su di essa vengono cancellati i filtri
 */
export enum CandidacyRoutesEnum {
    PREFIX = "candidacy",
    LIST_POS = 'candidacy.list-pos',
    EDIT = 'candidacy.edit'
}