import { ActionTree }   from 'vuex';
import { ProviderTechnicianState }    from './state';
import { AppState }     from '@/store/types';
import { providerTechniciansService } from '@services/providerTechnicians.service';

interface FetchAppointmentsParams {
    technicianId: number;

    providerId?: number;

    /**
     * Month to fetch events for
     * String format: YYYY-MM
     */
    month: string; 
}

export interface Actions {
    fetchAppointments(data?: FetchAppointmentsParams): Promise<void>; 
    fetchAvailabilities(data?: FetchAppointmentsParams): Promise<void>; 
}


const actions: ActionTree<ProviderTechnicianState, AppState> = {
    async fetchAppointments(
        { commit },  
        data: FetchAppointmentsParams
    ): Promise<void> {
        try {
            const response = await providerTechniciansService.appointments(
                data?.technicianId,
                data?.providerId,
                data?.month
            );

            commit("setMonth", data.month);
            commit("setAppointments", response);
        } catch (e) {
            console.error(e);
            // handleError(commit, e);
        }

    },
    async fetchAvailabilities(
        { commit },  
        data: FetchAppointmentsParams
    ): Promise<void> {
        try {
            const response = await providerTechniciansService.availabilities(
                data?.technicianId,
                data?.providerId,
                data?.month
            );

            commit("setMonth", data.month);
            commit("setAvailabilities", response);
        } catch (e) {
            console.error(e);
            // handleError(commit, e);
        }

    },
    
}

export default actions;