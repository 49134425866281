import { RouteRecordRaw } from 'vue-router';
import { UsersRoutesEnum } from './UsersRoutesEnum';

const UsersMainPage = () => import(/* webpackChunkName: "Users-module" */ '../pages/UsersMainPage.vue');
const ProviderListPage = () => import(/* webpackChunkName: "Users-module" */ '../pages/provider/ProviderListPage.vue');
const ProviderEditPage = () => import(/* webpackChunkName: "Users-module" */ '../pages/provider/ProviderEditPage.vue');
const UserListPage = () => import(/* webpackChunkName: "Users-module" */ '../pages/user/UserListPage.vue');
const UserEditPage = () => import(/* webpackChunkName: "Users-module" */ '../pages/user/UserEditPage.vue');
const AdminListPage = () => import(/* webpackChunkName: "Users-module" */ '../pages/admin/AdminListPage.vue');
const AdminEditPage = () => import(/* webpackChunkName: "Users-module" */ '../pages/admin/AdminEditPage.vue');

const routes: Array<RouteRecordRaw> = [
	{
		path: '/utenti',
		name: UsersRoutesEnum.MAIN,
		props: true,
		component: UsersMainPage,
		meta: {
			tabTitle: "admin.users_dashboard.title"
		}
	},
	{
		path: '/elenco-admin',
		name: UsersRoutesEnum.ADMIN_LIST,
		props: true,
		component: AdminListPage,
		meta: {
			tabTitle: "admin.admins.list.title"
		}
	},
	{
		path: '/dettaglio-admin/:adminId',
		name: UsersRoutesEnum.ADMIN_EDIT,
		props: true,
		component: AdminEditPage,
		meta: {
			tabTitle: "admin.admins.detail.title"
		}
	},
	{
		path: '/elenco-provider',
		name: UsersRoutesEnum.PROVIDER_LIST,
		props: true,
		component: ProviderListPage,
		meta: {
			tabTitle: "admin.providers.list.title"
		}
	},
	{
		path: '/dettaglio-provider/:providerId',
		name: UsersRoutesEnum.PROVIDER_EDIT,
		props: true,
		component: ProviderEditPage,
		meta: {
			tabTitle: "admin.providers.detail.title"
		}
	},
	{
		path: '/elenco-user',
		name: UsersRoutesEnum.USER_LIST,
		props: true,
		component: UserListPage,
		meta: {
			tabTitle: "admin.users.list.title"
		}
	},
	{
		path: '/dettaglio-user/:userId',
		name: UsersRoutesEnum.USER_EDIT,
		props: true,
		component: UserEditPage,
		meta: {
			tabTitle: "admin.users.detail.title"
		}
	}
];

export { routes };
export { UsersRoutesEnum };