import { notificationsService } from "@services/notifications.service";
import { InjectionKey } from "vue";
import { createStore, createLogger, Store, useStore } from "vuex";

import { AppState } from "./types";

export const key_store: InjectionKey<Store<AppState>> = Symbol()

export function useAppStore() {
  return useStore(key_store);
}

export default createStore<AppState>({
  state: {
    counter: 0,
    notificationCount: 0
  },

  mutations: {
    setNotificationCount(state, count) {
      state.notificationCount = count;
    },

    increment(state) {
      state.counter++;
    },

    decrement(state) {
      state.counter--;
    }
  },

  actions: {
    async updateNotificationCount({ commit }) {
      //const response = await notificationsService.fetchNotifications('false');

      //const notRead = response.filter( n => !n.is_read);
      const count = await notificationsService.getNotificationsCount('true');

      commit("setNotificationCount", count);
    }
  },
  plugins: process.env.NODE_ENV !== 'production' ? [createLogger()] : []
})
