import { Appointment } from '@/model/api/appointment';
import { MutationTree } from 'vuex';
import { ProviderTechnicianState } from './state';

export interface Mutations {
    setAvailabilities(data: any[]): void;
    setAppointments(data: Appointment[]): void;
    setMonth(month: Appointment[]): void;
}

const mutations: MutationTree<ProviderTechnicianState> = {
    setAppointments(state, data: Appointment[]) {
        state.appointments = data;
    },
    setAvailabilities(state, data: Appointment[]) {
        state.availabilities = data;
    },
    setMonth(state, month: string) {
        state.month = month;
    },
}

export default mutations;