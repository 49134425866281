import { AppState } from '@/store/types';
import { posCalendarService } from '@services/posCalendar.service';
import { providerCalendarService } from '@services/providerCalendar.service';
import { ActionTree } from 'vuex';
import { CalendarState } from './state';

interface FetchEventsParams {
    /**
     * Month to fetch events for
     * String format: YYYY-MM
     */
    month: string;
    serviceId?: number;
}
interface FetchPosEventsParams extends FetchEventsParams {
    currentPosId: number;
    forcustomer: string;
    available: boolean;
}
interface FetchPosDayEventsParams {
    currentPosId: number;
    date: string;
}
interface FetchProviderEventsParams extends FetchEventsParams {
    currentProviderId: number;
    technicianId?: number;
}

interface ProviderActions {
    fetchEvents(data: FetchProviderEventsParams): Promise<void>;
}

interface PosActions {
    fetchPosEvents(data: FetchPosEventsParams): Promise<void>;
    fetchPosDayEvents(data: FetchPosDayEventsParams): Promise<void>;
}

export interface Actions
    extends ProviderActions, PosActions {
}

const actions: ActionTree<CalendarState, AppState> = {
    async fetchEvents({ commit }, data: FetchProviderEventsParams) {
        try {
            const response = await providerCalendarService.index(
                data.currentProviderId,
                data.month,
                data.serviceId,
                data.technicianId
            );

            commit('setMonth', data.month);
            commit('setMonthEvents', response);

            return response;
        } catch (e) {
            console.error(e);
            // handleError(commit, e);
        }

    },

    async fetchPosEvents({ commit }, data: FetchPosEventsParams) {
        try {
            const response = await posCalendarService.index(
                data.currentPosId,
                data.month,
                data.serviceId,
                data.forcustomer,
                data.available
            );

            commit('setPosMonth', data.month);
            commit('setPosMonthEvents', response);

            return response;
        } catch (e) {
            console.error(e);
            // handleError(commit, e);
        }

    },

    async fetchPosDayEvents({ commit }, data: FetchPosDayEventsParams) {
        try {
            const response = await posCalendarService.day(
                data.currentPosId,
                data.date,
            );

            commit('setPosDate', data.date);
            commit('setPosDayEvents', response);

            return response;
        } catch (e) {
            console.error(e);
            // handleError(commit, e);
        }

    }
}

export default actions;