import { RouteRecordRaw } from 'vue-router';

export enum ProviderServicesRoutesEnum {
	PORTFOLIO_SERVICES_PREFIX = "provider-portfolio-services",
	PORTFOLIO_SERVICES_LIST = 'provider-portfolio-services.list',
	SERVICES_FEEDBACKS_LIST = 'provider-services-feedbacks.list',
	PORTFOLIO_SERVICES_EDIT = 'provider-portfolio-services.edit',
}

const PortfolioServicesListPage = () => import(/* webpackChunkName: "Provider-Services-module" */ '../pages/provider/PortfolioServicesListPage.vue');
const ServicesFeedbacksListPage = () => import(/* webpackChunkName: "Provider-Services-module" */ '../pages/provider/ServicesFeedbacksListPage.vue');
const PortfolioServiceEditPage = () => import(/* webpackChunkName: "Provider-Services-module" */ '../pages/provider/PortfolioServiceEditPage.vue');

export const routes: Array<RouteRecordRaw> = [
	{
		path: '/provider/portfolio-servizi',
		name: ProviderServicesRoutesEnum.PORTFOLIO_SERVICES_LIST,
		props: true,
		component: PortfolioServicesListPage,
		meta: {
			tabTitle: "provider.portfolio.list.title"
		}
	},
	{
		path: '/provider/feedback-servizi',
		name: ProviderServicesRoutesEnum.SERVICES_FEEDBACKS_LIST,
		props: true,
		component: ServicesFeedbacksListPage,
		meta: {
			tabTitle: "provider.services.feedbacks.list.title"
		}
	},
	{
		path: '/provider/dettaglio-servizio/:serviceId',
		name: ProviderServicesRoutesEnum.PORTFOLIO_SERVICES_EDIT,
		props: true,
		component: PortfolioServiceEditPage,
		meta: {
			tabTitle: "provider.portfolio.detail.title"
		}
	}
];