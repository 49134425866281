import { User } from '@/model/api/User';
import { AppState } from '@/store/types';
import {authService} from '@/services/auth.service';
import { ActionTree } from 'vuex';
import { AuthState } from './state';
import { providerTechniciansService } from '@services/providerTechnicians.service';

export interface Actions {
    getMe(): Promise<any>;

    updateMe(user: User): void;

    getAppProfile(id: number): Promise<any>;
}


const actions: ActionTree<AuthState, AppState> = {
    async getMe({ commit }) {
        const response = await authService.me();
        commit('setMe', response);
        return response;
    },

    async getAppProfile({ commit }, id: number) {
        const appProfile = await providerTechniciansService.getProfile(id);
        commit('setAppProfile', appProfile);
        return appProfile;
    },

    async updateMe({ commit }, me: User) {
        try {
            const response = await authService.updateMe(me);
            commit('setMe', response);
            return response;
        } catch (e) {
            console.error(e);
            // handleError(commit, e);
        }
    },
}

export default actions;