
import { defineComponent } from "vue";

export default defineComponent({
  props: ["value"],
  computed: {
    formatted() {
      if (typeof this.value === "number") {
        return (this as any).value?.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' });
      } else {
        return "NaN";
      }
    },
  },
});
