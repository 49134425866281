import { configuration } from "@/plugins/Config-plugin";
import { localStorageSVC } from "@/plugins/LocalStorage-plugin";
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios';

export class HttpService {

    public http: AxiosInstance;
    
    protected source: CancelTokenSource;

    public cancelPendingRequests() {
        console.debug("CRUD cancelPendingRequests");
        this.source?.cancel();
    }

    constructor(apiBaseUrl?: string) {
        this.http = axios.create({
            baseURL: apiBaseUrl || this._getApiBaseUrl(),
            headers: {
                'Accept': 'application/json'
            }
        });

        this._addInterceptorRequest();
    }

    private _getApiBaseUrl() {
        const apiBaseUrl = configuration.apiBaseUrl;

        if (!apiBaseUrl || apiBaseUrl === '') {
            throw Error("Api base URL required");
        }

        return apiBaseUrl;
    }


    private _addInterceptorRequest() {
        this.http.interceptors.request.use(
            (config: AxiosRequestConfig) => {

                config.params = {
                    ...config.params,
                    // key: "1bebb7e0"
                }

                if (localStorageSVC.accessToken) {
                    config.headers['Authorization'] = `Bearer ${localStorageSVC.accessToken}`;
                }

                return config;
            }
        );

        this.http.interceptors.response.use(
            (response: AxiosResponse) => {
                return response;
            },
            (error: AxiosError) => {
                if (error.response.status == 401) {
                    localStorageSVC.clearToken();
                    window.location.reload();
                }

                return Promise.reject(error);
            }
        );
    }

    async post<T>(url: string, data?: any, config?: any): Promise<T> {
        const response = this.http.post<T>(url, data, config);

        return response
            .then(this._handleSuccess)
            .catch(this._handleError);
    }

    async put<T>(url: string, data: any, config?: any): Promise<T> {
        const response = this.http.put<T>(url, data, config);

        return response
            .then(this._handleSuccess)
            .catch(this._handleError);
    }

    async patch<T>(url: string, data: any, config?: any): Promise<T> {
        const response = this.http.patch<T>(url, data, config);

        return response
            .then(this._handleSuccess)
            .catch(this._handleError);
    }

    async get<T>(url: string, config?: any): Promise<T> {
        const response = this.http.get<T>(url, config);

        return response
            .then(this._handleSuccess)
            .catch(this._handleError);
    }

    async delete<T>(url: string, data?: any, config?: any): Promise<T> {
        const response = this.http.delete<T>(url, {
            ...config, data
        });

        return response
            .then(this._handleSuccess)
            .catch(this._handleError);
    }

    private _handleSuccess(response: AxiosResponse): Promise<any> {
        return response.data;
    }

    private _handleError(error: AxiosError): Promise<any> {
        return Promise.reject(error.response);
    }

}
