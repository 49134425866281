import { Module } from 'vuex';

import { CreateActionsHandler, CreateGettersHandler, CreateMutationsHandler } from '@/store/common';
import { ModulesEnum }  from '@/store/modules';
import { AppState }    from '@/store/types';

import actions, { Actions }     from './actions';
import getters, { Getters }     from './getters';
import mutations, { Mutations } from './mutations';
import state, { CalendarState }     from './state';

export const CalendarStoreModule: Module<CalendarState, AppState> = {
    namespaced: true,

    state,
    mutations   : { ...mutations },
    actions     : { ...actions  },
    getters     : { ...getters  },
};

export const calendarStore = {
    actions:    CreateActionsHandler<Actions, CalendarState>(ModulesEnum.CALENDAR,      CalendarStoreModule),
    mutations:  CreateMutationsHandler<Mutations, CalendarState>(ModulesEnum.CALENDAR,  CalendarStoreModule),
    getters:    CreateGettersHandler<Getters, CalendarState>(ModulesEnum.CALENDAR,      CalendarStoreModule)
}