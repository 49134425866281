import { Module } from 'vuex';

import { CreateActionsHandler, CreateGettersHandler, CreateMutationsHandler } from '@/store/common';
import { ModulesEnum }  from '@/store/modules';
import { AppState }    from '@/store/types';

import actions, { Actions }     from './actions';
import getters, { Getters }     from './getters';
import mutations, { Mutations } from './mutations';
import state, { ProviderTechnicianState }     from './state';

export const ProviderTechnicianStore: Module<ProviderTechnicianState, AppState> = {
    namespaced: true,

    state,
    mutations   : { ...mutations },
    actions     : { ...actions  },
    getters     : { ...getters  },
};

export default {
    actions:    CreateActionsHandler<Actions, ProviderTechnicianState>(ModulesEnum.PROV_TECH,      ProviderTechnicianStore),
    mutations:  CreateMutationsHandler<Mutations, ProviderTechnicianState>(ModulesEnum.PROV_TECH,  ProviderTechnicianStore),
    getters:    CreateGettersHandler<Getters, ProviderTechnicianState>(ModulesEnum.PROV_TECH,      ProviderTechnicianStore)
}