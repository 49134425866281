import { RouteRecordRaw } from 'vue-router';
import { ProviderTechnicianRoutesEnum } from './ProviderTechnicianRoutesEnum';

const AppointmentsPage  = () => import(
    /* webpackChunkName: "ProviderTechnician-module" */ 
    '../pages/appointments/AppointmentsPage.vue'
);
const AvailabilitiesPage  = () => import(
    /* webpackChunkName: "ProviderTechnician-module" */ 
    '../pages/availabilities/AvailabilitiesPage.vue'
);
const ServicesPage  = () => import(
    /* webpackChunkName: "ProviderTechnician-module" */ 
    '../pages/services/ServicesPage.vue'
);

const routes:  Array<RouteRecordRaw> = [
    {
        path: '/appointments',
        name: ProviderTechnicianRoutesEnum.APPOINTMENTS,
        component: AppointmentsPage,
        meta: {
          tabTitle: 'provider_tech.appointements.title',
        }
    },
    {
        path: '/availabilities',
        name: ProviderTechnicianRoutesEnum.AVAILABILITIES,
        component: AvailabilitiesPage,
        meta: {
            tabTitle: 'provider_tech.availabilities.title',
        }
    },
    {
        path: '/services',
        name: ProviderTechnicianRoutesEnum.SERVICES,
        component: ServicesPage,
        meta: {
            tabTitle: 'provider_tech.services.title',
        }
    },
];

export { routes };
export { ProviderTechnicianRoutesEnum };