import { EntityTypeEnum } from '@/model/enums/EntityTypeEnum';
import { RouteRecordRaw } from 'vue-router';
import { CandidacyRoutesEnum } from './CandidacyRoutesEnum';

const CandidacyListPage = () => import(/* webpackChunkName: "POS-module" */ '../pages/CandidacyListPage.vue');
const CandidacyEditPage = () => import(/* webpackChunkName: "POS-module" */ '../pages/CandidacyEditPage.vue');

const routes: Array<RouteRecordRaw> = [
	{
		path: '/candidature-pos',
		name: CandidacyRoutesEnum.LIST_POS,
		props: route => ({
			type: EntityTypeEnum.POS,
			...route.params
		}),
		component: CandidacyListPage,
		meta: {
			tabTitle: "admin.candidacies.pos.list.title"
		}
	},
	{
		path: '/dettaglio-candidatura-pos/:candidacyId',
		name: CandidacyRoutesEnum.EDIT,
		props: route => ({
			type: EntityTypeEnum.POS,
			...route.params
		}),
		component: CandidacyEditPage,
		meta: {
			tabTitle: "admin.candidacies.pos.detail.title"
		}
	}
];

export { routes };
export { CandidacyRoutesEnum };