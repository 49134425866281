import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({})
export default class ButtonIcon extends Vue {
  @Prop({ default: null })
  readonly label: string;

  @Prop({ default: null })
  readonly image: string;

  @Prop({ default: true })
  readonly imageRight: boolean;

  @Prop({ default: false })
  readonly onlyIcon: boolean;
}