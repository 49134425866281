{
  "login.title": "Accedi al sistema",
  "login.subtitle": "Compila i campi sottostanti per effettuare l'accesso alla tua area personale per la gestione dei tuoi servizi.",
  "generic.email_label": "Email",
  "generic.email_label.tooltip": "Inserisci email",
  "generic.password_label": "Password",
  "generic.password_label.tooltip": "Inserisci password",
  "login.title.button": "Login",
  "generic.filter.selectyear": "Seleziona un anno",
  "generic.filter.selectmonth": "Seleziona un mese",
  "generic.months.jan": "Gennaio",
  "generic.months.feb": "Febbraio",
  "generic.months.mar": "Marzo",
  "generic.months.apr": "Aprile",
  "generic.months.may": "Maggio",
  "generic.months.june": "Giugno",
  "generic.months.july": "Luglio",
  "generic.months.aug": "Agosto",
  "generic.months.sept": "Settembre",
  "generic.months.oct": "Ottobre",
  "generic.months.nov": "Novembre",
  "generic.months.dec": "Dicembre",
  "login.wrong_credentials": "Credenziali non valide",
  "login.wrong_role": "Accesso non consentito per questo utente",
  "login.password_lost": "Password dimenticata?",
  "validation.required": "Campo richiesto",
  "doppleganger.title": "Accedi all'utente",
  "doppleganger.confirm": "Confermi l'accesso all'utente?",
  "reset_password.title": "Recupera la tua password",
  "reset_password.subtitle": "Inserisci l’indirizzo email che hai usato per registrarti",
  "reset_password.email_sent": "Email inviata! Segui le istruzioni ricevute.",
  "reset_password.subtitle_password": "Imposta la password. Ricorda che dovrai usare almeno 8 caratteri, un numero e un carattere speciale",
  "reset_password.invalid_token": "Il link potrebbere non essere più valido",
  "reset_password.password_changed": "Password modificata con successo",
  "reset_password.btn_submit_label": "Invia",
  "reset_password.input_new_psw_label": "Nuova password",
  "reset_password.input_confirm_psw_label": "Conferma nuova password",
  "lost_password.btn_submit_label": "Invia",
  "reset_password.confirm": "Confermi il reset della password?",
  "generic_error": "Errore generico",
  "generic_attention": "Attenzione",
  "generic_success": "Successo",
  "generic.btn_all": "Tutti",
  "generic.btn_none": "Nessuno",
  "replicate.grid.col.title": "Duplica",
  "replicate.button.tooltip": "Duplica il dato",
  "replicate.confirm": "Confermi di voler duplicare il dato?",
  "replicate.done": "Duplicazione effettuata.",
  "replicate.error": "Errore durante la duplicazione.",
  "pos.version-acceptance":"Condizione d'uso",
  "pos.acceptance":"Condizioni del: ",
  "dashboard.title": "LA TUA AREA PERSONALE",
  "admin.dashboard.subtitle": "Lorem ipsum admin.dashboard.subtitle",
  "admin.dashboard.btn_users_label": "Utenti <br />e Anagrafiche",
  "admin.users_dashboard.title": "Utenti",
  "admin.users_dashboard.subtitle": "Lorem ipsum admin.users_dashboard.subtitle",
  "admin.lookups_dashboard.title": "Anagrafiche",
  "admin.lookups_dashboard.subtitle": "Lorem ipsum admin.lookups_dashboard.subtitle",
  "admin.dashboard.btn_services_label": "Servizi",
  "admin.dashboard.btn_customer_orders_label": "Acquisti<br />Customer",
  "admin.dashboard.btn_pos_orders_label": "Acquisti<br />Punto Viasana",
  "admin.dashboard.btn_payments_invoices_label": "Pagamenti <br />e fatturazioni",
  "admin.dashboard.btn_coupon_label": "Coupon Emessi",
  "admin.dashboard.btn_pos_penalties_label": "Penali <br />e ammonizioni Punti Viasana",
  "admin.dashboard.btn_pos_performance_eval_label": "Giudizi<br />sui servizi Punti Viasana",
  "admin.dashboard.btn_provider_penalties_label": "Penali <br />e ammonizioni Provider",
  "admin.dashboard.btn_provider_performance_eval_label": "Giudizi<br />sui servizi Provider",
  "admin.dashboard.btn_technicians_label": "Tecnici",
  "admin.dashboard.btn_networks_label": "Network",
  "admin.dashboard.btn_sponsors_label": "Sponsor",
  
  "admin.dashboard.btn_stats": "Statistiche",
  "admin.adminstats_dashboard.title": "Statistiche Admin",
  "admin.adminstats_dashboard.subtitle": "Statistiche generiche destinate agli utenti admin",
  "admin.adminstats_dashboard.btn_pos_newservices_label": "Nuovi servizi aggiunti dai Punti Viasana",
  "admin.adminstats_dashboard.btn_pos_geobuyedservices_label": "Giornate acquistate dai Punti Viasana (Geo)",
  "admin.adminstats_dashboard.btn_customer_geobuyedservices_label": "Servizi acquistati dagli Utenti (Geo)",
  "adminstats.list.empty_message": "Nessun dato presente",

  "adminstats.posnewservices.title": "Nuovi servizi aggiunti dai Punti Viasana",
  "adminstats.posnewservices.table.pos": "Punto Viasana",
  "adminstats.posnewservices.table.inserted_service": "Servizio inserito",
  "adminstats.posnewservices.table.inserted_date": "Data inserimento",

  "adminstats.posgeobuyedservices.title": "Giornate acquistate dai Punti Viasana per provincia",
  "adminstats.posgeobuyedservices.table.service": "Servizio venduto",
  "adminstats.posgeobuyedservices.table.province": "Provincia",
  "adminstats.posgeobuyedservices.table.norders": "Num.",

  "adminstats.customersgeobuyedservices.title": "Servizi acquistati dai Clienti finali per provincia",
  "adminstats.customersgeobuyedservices.table.service": "Servizio venduto",
  "adminstats.customersgeobuyedservices.table.province": "Provincia",
  "adminstats.customersgeobuyedservices.table.norders": "Num.",

  "pos.dashboard.subtitle": "Questa è la tua dashboard. Gestisci tutti gli aspetti relativi ai servizi che proponi ai tuoi clienti.",
  "pos.dashboard.btn_portfolio_label": "La tua vetrina",
  "pos.dashboard.btn_portfolio_label.subtitle": "Consulta, verifica e modifica i dettagli dei servizi che stai offrendo ai tuoi clienti",
  "pos.dashboard.btn_buy_provider_service": "Acquista servizi da fornitori",
  "pos.dashboard.btn_buy_provider_service.subtitle": "Crea la tua offerta personalizzata aggiungendo servizi pronti per essere proposti ai tuoi clienti",
  "pos.dashboard.btn_book_customer_services_label": "Acquista per il tuo cliente",
  "pos.dashboard.btn_book_customer_services_label.subtitle": "Eroga direttamente un servizio ai clienti che non hanno effettuato la prenotazione.",
  "pos.dashboard.btn_add_new_service_label": "Aggiungi i tuoi servizi",
  "pos.dashboard.btn_add_new_service_label.subtitle": "Inserisci nella tua offerta i servizi che già gestisci e proponi direttamente ai tuoi clienti.",
  "pos.dashboard.btn_customer_purchases_label": "Le tue vendite",
  "pos.dashboard.btn_customer_purchases_label.subtitle": "Visualizza lo storico e il dettaglio dei servizi acquistati dai tuoi clienti",
  "pos.dashboard.btn_service_calendar":"Il tuo Calendario",
  "pos.dashboard.btn:service_calendar.subtitle":"Visualizza le giornate pianificate con i servizi che offri ai tuoi clienti. Seleziona il Servizio qui sotto per vedere i giorni in cui lo stai proponendo",
  "pos.dashboard.btn_issued_coupon_label": "Coupon di rimborso",
  "pos.dashboard.btn_issued_coupon_label.subtitle": "Consulta i Coupon per rimborsare i tuoi clienti a seguito dell’annullamento di un servizio o appuntamento.",
  "pos.dashboard.btn_promo_coupon_label": "Coupon promozionali",
  "pos.dashboard.btn_promo_coupon_label.subtitle": "Consulta i Coupon relativi a promozioni che hai attivato per i tuoi clienti.",
  "pos.dashboard.btn_coupon_label": "I tuoi Coupon",
  "pos.dashboard.btn_coupon_label.subtitle": "Consulta i Coupon che ti sono stati rilasciati come rimborso a seguito dell’annullamento di una giornata di servizi da parte di un fornitore.",
  "pos.dashboard.btn_provider_purchases_label": "I tuoi acquisti",
  "pos.dashboard.btn_provider_purchases_label.subtitle": "Visualizza lo storico e il dettaglio dei servizi che hai acquistato da fornitori.",
  "pos.dashboard.btn_payments_invoices_label": "Contabilità",
  "pos.dashboard.btn_payments_invoices_label.subtitle": "Consulta i dettagli di pagamento, le quote di incasso e le commissioni relative agli acquisti e alle vendite effettuare.",
  "pos.dashboard.btn_performance_management_label": "Valutazione dei clienti",
  "pos.dashboard.btn_performance_management_label.subtitle": "Visualizza il gradimento espresso dai clienti sui tuoi servizi.",
  "pos.dashboard.btn_charts_label": "Analisi e statistiche",
  "pos.dashboard.btn_charts_label.subtitle": "Consulta i grafici relativi all’andamento delle proposte offerte ai tuoi clienti",
  "pos.dashboard.btn_calendar_label": "Calendario servizi",
  "pos.dashboard.btn_calendar_label.subtitle": "Temporaneo da togliere",
  "pos.dashboard.btn_services_label": "OLD Servizi",
  "pos.dashboard.btn_services_label.subtitle": "Temporaneo da togliere",
  "pos.dashboard.btn_notifications_label": "Ultime notifiche",
  "pos.dashboard.btn_notifications_label.subtitle": "Temporaneo da togliere",
  "pos.dashboard_calendar.events_info": "Acquistati {booked} su {total}",
  "pos.dashboard_calendar.many_events": "In questa giornata hai programmato più di 2 esami.",
  "pos.dashboard_calendar.many_events.see_them": "VEDILI TUTTI",
  "pos.addservice.select_type": "Seleziona la tipologia di servizio da aggiungere",
  "pos.addservice.select.title": "AGGIUNGI I TUOI SERVIZI",
  "pos.addservice.select.subtitle": "In questa area puoi aggiungere alla tua proposta i servizi che già gestisci ed eroghi personalmente e in autonomia. Puoi scegliere di aggiungere un servizio all’interno di una singola giornata, ma anche pianificare un medesimo servizio su più giornate.",
  "pos.addservice.personalized.service":"Servizio personalizzato",
  "pos.addservice.select.single.description": "Aggiungi alla tua vetrina uno dei tuoi servizi personalizzati da erogare all’interno di una singola giornata.",
  "pos.addservice.recurring.service":"Servizio ricorrente",
  "pos.addservice.select.recursive.description": "Aggiungi alla tua vetrina uno dei tuoi servizi personalizzati e programmalo su più giornate.",
  "calendar.service.next_day": "Passa alla giornata precedente",
  "calendar.service.prev_day": "Passa alla giornata successiva",
  "calendar.service.edit": "Consulta e modifica i parametri della giornata di servizio, gli slot, il prezzo",
  "calendar.service.delete": "Cancella la disponibilità di questo esame nella giornata",
  "calendar.service.prev": "Passa all’esame precedente della giornata",
  "calendar.service.next": "Passa all’esame successivo della giornata",
  "calendar.service.delete_slot": "Cancella questa erogazione e rendi lo slot disponibile",
  "provider.dashboard.btn_services_label": "Servizi",
  "provider.dashboard.btn_payments_invoices_label": "Pagamenti <br />e fatturazioni",
  "provider.dashboard.btn_coupon_label": "Coupon Emessi",
  "profile.title": "Dati personali",
  "profile.btn_change_password_label": "Gestisci password",
  "change_password.title": "Gestisci password",
  "btn_save_label": "SALVA",
  "btn_quit_label": "ESCI",
  "user_name": "Nome",
  "user_name.tooltip": "Inserisci nome",
  "user_surname": "Cognome",
  "user_surname.tooltip": "Inserisci cognome",
  "user_email": "E-mail",
  "user_email.tooltip": "Inserisci e-mail",
  "user_role": "Ruolo",
  "user_role.tooltip": "Inserisci ruolo",
  "customer_fiscal_code": "Codice Fiscale",
  "customer_fiscal_code.tooltip": "Inserisci codice fiscale",
  "generic.input_name_label": "Nome",
  "generic.input_name_label.tooltip": "Inserisci nome",
  "btn_accept":"Accetta",
  "btn_decline":"Rifiuta",
  "btn_go_back_label": "Torna indietro",
  "btn_step_back_label": "Indietro",
  "btn_step_next_label": "Avanti",
  "btn_step_jump_label": "Salta",
  "btn_step_buy_label": "Aquista",
  "data_saved_success": "Le tue scelte sono state memorizzate",
  "profile.change_password.input_old_psw_label": "Inserisci la tua vecchia password",
  "profile.change_password.input_old_psw_label.tooltip": "Inserisci la tua vecchia password",
  "profile.change_password.input_new_psw_label": "Inserisci la tua nuova password",
  "profile.change_password.input_new_psw_label.tooltip": "Inserisci la tua nuova password",
  "profile.change_password.input_confirm_psw_label": "Re-Inserisci la tua nuova password",
  "profile.change_password.input_confirm_psw_label.tooltip": "Re-Inserisci la tua nuova password",
  "admin.services.list.title": "Elenco dei servizi",
  "provider.dashboard.subtitle": "Lorem ipsum provider.dashboard.subtitle",
  "change_password.subtitle": "Lorem ipsum change_password.subtitle",
  "profile.subtitle": "Lorem ipsum profile.subtitle",
  "provider.profile.subtitle": "Lorem ipsum provider.profile.subtitle",
  "admin.candidacies.detail.subtitle": "Lorem ipsum admin.candidacies.detail.subtitle",
  "admin.candidacies.list.subtitle": "Lorem ipsum admin.candidacies.list.subtitle",
  "pos.profile.subtitle": "Lorem ipsum pos.profile.subtitle",
  "admin.pos_categories.detail.subtitle": "Lorem ipsum admin.pos_categories.detail.subtitle",
  "admin.pos_categories.list.subtitle": "Lorem ipsum admin.pos_categories.list.subtitle",
  "admin.pos.detail.subtitle": "Lorem ipsum admin.pos.detail.subtitle",
  "admin.pos.list.subtitle": "Lorem ipsum admin.pos.list.subtitle",
  "admin.service_categories.detail.subtitle": "Lorem ipsum admin.service_categories.detail.subtitle",
  "admin.service_categories.list.subtitle": "Lorem ipsum admin.service_categories.list.subtitle",
  "admin.networks.detail.subtitle": "Lorem ipsum admin.networks.detail.subtitle",
  "admin.networks.list.subtitle": "Lorem ipsum admin.networks.list.subtitle",
  "admin.sponsors.detail.subtitle": "Lorem ipsum admin.sponsors.detail.subtitle",
  "admin.sponsors.list.subtitle": "Lorem ipsum admin.sponsors.list.subtitle",
  "admin.services.detail.subtitle": "Lorem ipsum admin.services.detail.subtitle",
  "admin.services.list.subtitle": "Lorem ipsum admin.services.list.subtitle",
  "admin.services.visibility.subtitle": "Lorem ipsum admin.services.visibility.subtitle",
  "provider.portfolio.detail.subtitle": "Lorem ipsum provider.portfolio.detail.subtitle",
  "provider.portfolio.list.subtitle": "Lorem ipsum provider.portfolio.list.subtitle",
  "provider.technicians.detail.subtitle": "Lorem ipsum provider.technicians.detail.subtitle",
  "provider.technicians.list.subtitle": "Lorem ipsum provider.technicians.list.subtitle",
  "admin.providers.detail.subtitle": "Lorem ipsum admin.providers.detail.subtitle",
  "admin.providers.list.subtitle": "Lorem ipsum admin.providers.list.subtitle",
  "admin.services.btn_go_network_label": "Gestisci network",
  "admin.services.btn_go_service_categories_label": "Gestisci categorie di servizio",
  "btn_go_dashboard_label": "Torna alla Dashboard",
  "btn_add_new_label": "AGGIUNGI",
  "btn_attachments_label": "Sfoglia",
  "admin.networks.list.title": "Network",
  "admin.networks.detail.title": "Dettaglio network",
  "admin.sponsors.list.title": "Sponsor",
  "admin.sponsors.detail.title": "Dettaglio sponsor",
  "btn_edit_label": "Modifica",
  "btn_details_label": "Dettagli",
  "generic.input_note_label": "Note",
  "generic.input_note_label.tooltip": "Inserisci note",
  "generic.input_state_label": "Stato",
  "generic.input_state_label.tooltip": "Seleziona lo stato",
  "btn_delete_label": "Elimina",
  "confirm_delete": "Confermi l'eliminazione?",
  "active_label": "Attivo",
  "inactive_label": "Inattivo",
  "yes_label": "Sì",
  "no_label": "No",
  "loading_label": "Caricamento...",
  "admin.service_categories.list.title": "Categorie di servizio",
  "admin.service_categories.detail.title": "Dettaglio categoria servizi",
  "admin.service_categories.generic.categories_label": "Categorie",
  "admin.service_categories.generic.categories_label.tooltip": "Seleziona categorie",
  "admin.service_categories.generic.service_provider_label": "Servizio provider",
  "admin.service_categories.generic.service_provider_label.tooltip": "Scegli se è un servizio provider",
  "admin.service_categories.list.btn_visibility_label": "Gestisci visibilità",
  "admin.services.detail.title": "Dettaglio servizio",
  "provider.portfolio.detail.title": "Dettaglio servizio",
  "admin.service.detail.pos_categories_label": "Tipologie Punti Viasana",
  "admin.service.detail.pos_categories_label.tooltip": "Seleziona tipologie Punti Viasana",
  "admin.pos_categories.list.title": "Tipologie Punti Viasana",
  "form_configuration_label": "Dati di configurazione",
  "admin.services.visibility.title": "Visibilità - Singolo servizio",
  "generic.country_label": "Nazione",
  "generic.country_label.tooltip": "Seleziona nazione",
  "generic.region_label": "Regione",
  "generic.region_label.tooltip": "Inserisci regione",
  "generic.province_label": "Provincia",
  "generic.province_label.tooltip": "Inserisci provincia",
  "admin.services.visibility.input_from_date_label": "Da",
  "admin.services.visibility.input_from_date_label.tooltip": "Inserisci la data di inizio",
  "admin.services.visibility.input_to_date_label": "A",
  "admin.services.visibility.input_to_date_label.tooltip": "Inserisci la data di fine",
  "all_plur_fem_label": "Tutte",
  "admin.users_dashboard.btn_admin_label": "Admin",
  "admin.providers.list.title": "Elenco Provider",
  "generic.description_label": "Descrizione",
  "generic.description_label.tooltip": "Inserire descrizione",
  "generic.business_name_label": "Ragione sociale",
  "generic.business_name_label.tooltip": "Inserisci ragione sociale",
  "generic.city_label": "Città",
  "generic.city_label.tooltip": "Inserisci città",
  "generic.foreign_province_label": "Provincia estera",
  "generic.foreign_province_label.tooltip": "Inserisci provincia estera",
  "generic.zip_label": "CAP",
  "generic.zip_label.tooltip": "Inserisci CAP",
  "admin.pos.detail.contract_status_label": "Stato contratto",
  "admin.providers.detail.contract_status_label": "Stato contratto",
  "admin.users_dashboard.btn_provider_label": "Provider",
  "provider.technicians.list.title": "Tecnici",
  "admin.technicians.list.title": "Tecnici",
  "admin.users_dashboard.btn_pos_label": "Punti Viasana",
  "admin.users_dashboard.btn_customer_label": "Clienti",
  "fee_type.monthly": "Mensile",
  "fee_type.yearly": "Annuale",
  "admin.providers.detail.btn_pos_feedback_label": "Feedback aggregati di tutti i Punti Viasana",
  "admin.providers.detail.btn_provider_orders.label": "Storico ordini Provider",
  "admin.providers.detail.btn_provider_orders.subtitle": "Tutti gli acquisti fatti da Punti Viasana",
  "admin.providers.detail.btn_pos_payments.label": "Stato pagamenti Punti Viasana",
  "admin.providers.detail.btn_pos_payments.subtitle": "Nei confronti di Viasana",
  "admin.providers.detail.btn_technicians_label": "Rete tecnici",
  "admin.providers.detail.btn_calendar_label": "Val al calendario",
  "admin.providers.detail.btn_penalties.label": "Ammonizioni",
  "admin.providers.detail.btn_penalties.subtitle": "Vai a dettaglio",
  "admin.providers.detail.title": "Dettaglio Provider",
  "generic.general_info_title": "Info generali",
  "feedback.section_title": "Valutazione",
  "generic.business_office_label": "Sede legale",
  "generic.contacts_label": "Contatti",
  "generic.billing_data": "Dati fatturazione",
  "generic.contract_data": "Dati di contratto",
  "generic.cap_or_zip_label": "CAP o ZIP",
  "generic.cap_or_zip_label.tooltip": "Inserisci CAP o ZIP",
  "generic.address_label": "Indirizzo",
  "generic.address_label.tooltip": "Inserisci indirizzo",
  "generic.longitude_label": "Coordinate - Longitudine",
  "generic.longitude_label.tooltip": "Inserisci la longitudine",
  "generic.latitude_label": "Coordinate - Latitudine",
  "generic.latitude_label.tooltip": "Inserisci la latitudine",
  "generic.business_country_label": "Nazione sede legale",
  "generic.business_country_label.tooltip": "Inserisci nazione sede legale",
  "generic.business_region_label": "Regione sede legale",
  "generic.business_region_label.tooltip": "Inserisci regione sede legale",
  "generic.business_province_label": "Provincia sede legale",
  "generic.business_province_label.tooltip": "Inserisci provincia sede legale",
  "generic.business_city_label": "Città sede legale",
  "generic.business_city_label.tooltip": "Inserisci città sede legale",
  "generic.business_foreign_province_label": "Provincia estera sede legale",
  "generic.business_foreign_province_label.tooltip": "Inserisci provincia estera sede legale",
  "generic.business_address_label": "Indirizzo sede legale",
  "generic.business_address_label.tooltip": "Inserisci indirizzo sede legale",
  "generic.business_zip_label": "CAP o ZIP sede legale",
  "generic.business_zip_label.tooltip": "Inserisci CAP o ZIP sede legale",
  "generic.pec_label": "Casella PEC",
  "generic.pec_label.tooltip": "Inserisci casella PEC",
  "generic.phone_label": "Numero di telefono",
  "generic.phone_label.tooltip": "Inserisci il numero di telefono",
  "generic.internal_reference_label": "Riferimento interno",
  "generic.internal_reference_label.tooltip": "Inserisci riferimento interno",
  "generic.internal_reference_phone_label": "Cellulare riferimento interno",
  "generic.internal_reference_phone_label.tooltip": "Inserisci cellulare riferimento interno",
  "generic.fiscal_code_label": "Codice fiscale",
  "generic.fiscal_code_label.tooltip": "Inserisci codice fiscale",
  "generic.vat_code_label": "Partita IVA",
  "generic.vat_code_label.tooltip": "Inserisci partita IVA",
  "generic.sdi_code_label": "Codice SDI",
  "generic.sdi_code_label.tooltip": "Inserisci codice SDI",
  "generic.iban_label": "IBAN",
  "generic.iban_label.tooltip": "Inserisci IBAN",
  "generic.bank_name_label": "Nome banca o filiale",
  "generic.bank_name_label.tooltip": "Inserisci nome banca o filiale",
  "generic.contract_activation_date_label": "Data attivazione contratto",
  "generic.contract_activation_date_label.tooltip": "Inserisci data attivazione contratto",
  "generic.contract_expiry_date_label": "Data scadenza contratto",
  "generic.contract_expiry_date_label.tooltip": "Inserisci data scadenza contratto",
  "generic.apply.commitions.at":"Data applicazione commissioni",
  "generic.apply.commitions.at.tooltip":"Inserisci data applicazione commissioni",
  "generic.fee_type_label": "Tipologia Fee",
  "generic.fee_type_label.tooltip": "Inserisci tipologia Fee",
  "generic.fee_amount_label": "Importo Fee €",
  "generic.fee_amount_label.tooltip": "Inserisci importo Fee €",
  "provider.dashboard.btn_calendar_label": "Calendario",
  "provider.dashboard.btn_pos_history_label": "Storico Punti Viasana",
  "provider.dashboard.btn_orders_label": "Ordini",
  "provider.dashboard.btn_customers_label": "Clienti",
  "provider.dashboard.btn_payments_billing_label": "Pagamenti e fatturazione",
  "provider.dashboard.btn_performance_management_label": "Giudizi sui servizi",
  "provider.dashboard.btn_technicians_label": "Rete di Tecnici",
  "provider.profile.pos_note_label": "Presentazione per i Punti Viasana",
  "provider.profile.pos_note_label.tooltip": "Inserisci presentazione per i Punti Viasana",
  "pos.profile.customer_note_label": "Presentazione per i Clienti",
  "pos.profile.customer_note_label.tooltip": "Inserisci presentazione per i Clienti",
  "provider.profile.title": "Area personale",
  "pos.profile.title": "Area personale",
  "pos.invoices.list.title": "CONTABILITA’",
  "pos.invoices.list.subtitle": "Dettagli di pagamento e di incasso",
  "pos.invoices.list_card.subtitle": "In quest’area puoi consultare i dettagli di pagamento effettuati e ricevuti assieme alle quote di incasso e alle commissioni che riguardano i Servizi acquistati da fornitori e quelli venduti ai tuoi clienti.",
  "provider.profile.docs_for_pos_label": "Caricamento documenti visibili ai Punti Viasana",
  "provider.profile.docs_for_pos_label.tooltip": "Carica documenti visibili ai Punti Viasana",
  "pos.profile.docs_for_customer_label": "Caricamento documenti visibili ai Clienti",
  "pos.profile.docs_for_customer_label.tooltip": "Carica documenti visibili ai Clienti",
  "upload_success": "File caricato",
  "upload_deleted_success": "File eliminato",
  "generic.choose_file_label": "Sfoglia",
  "provider.portfolio.list.title": "Programmazione servizi",
  "provider.portfolio.detail.service_categories_label": "Categorie servizio",
  "provider.portfolio.detail.network_label": "Network",
  "provider.portfolio.detail.cost_label": "Costo",
  "provider.portfolio.detail.technicians_count_label": "N° tecnici",
  "provider.portfolio.detail.technicians_list_label": "Tecnici",
  "provider.portfolio.detail.config_status_label": "Stato configurazione",
  "provider.portfolio.detail.duration_label": "Durata (minuti)",
  "provider.portfolio.detail.config_status_not_complete_label": "Non completo",
  "provider.portfolio.detail.config_status_complete_label": "Completo",
  "generic.not_published": "In bozza",
  "generic.published": "In vetrina",
  "provider.portfolio.list.msg_full_services_label": "Tutti i servizi sono già aggiunti",
  "login.remember_me_label": "Ricordami",
  "provider.portfolio.list.btn_add_service_label": "Aggiungi nuovo servizio",
  "generic.info_label": "Info",
  "generic.not_configured_label": "Non configurato",
  "generic.configured_label": "Configurato",
  "generic.publish_status_label": "Stato pubblicazione",
  "generic.service_policy_label": "Condizioni del servizio",
  "generic.service_policy_label.tooltip": "Inserisci condizioni del servizio",
  "generic.attachments_label": "Allegati per Clienti",
  "pos.attachments_label": "Allegati per Punti Viasana",
  "exam.attachments_label": "Come prepararsi all'esame",
  "generic.attachments_label.tooltip": "Carica un allegato",
  "generic.attachment_description_label": "Descrizione allegato",
  "generic.attachment_description_label.tooltip": "Inserisci una breve descrizione che identifica il contenuto dell’allegato.",
  "provider.portfolio.detail.associated_techs_label": "Tecnici associati",
  "provider.portfolio.detail.cost_vat_exc_label": "Costo (IVA esclusa)",
  "provider.portfolio.detail.cost_vat_exc_label.tooltip": "Inserisci costo (IVA esclusa)",
  "generic.vat": "IVA",
  "generic.vat.tooltip": "Seleziona IVA",
  "provider.portfolio.detail.from_time_label": "Dalle",
  "provider.portfolio.detail.from_time_label.tooltip": "Inserisci orario di inizio",
  "provider.portfolio.detail.to_time_label": "Alle",
  "provider.portfolio.detail.to_time_label.tooltip": "Inserisci orario di fine",
  "provider.portfolio.detail.publish_label": "Pubblica",
  "provider.portfolio.detail.publish_label.tooltip": "Seleziona lo stato di pubblicazione",
  "generic.menu": "MENU",
  "generic.regions_label": "Regioni",
  "generic.regions_label.tooltip": "Seleziona regioni",
  "generic.provinces_label": "Province",
  "generic.provinces_label.tooltip": "Seleziona province",
  "msg_form_changed": "Ci sono delle modifiche non salvate. Uscire?",
  "msg_form_prise_zero": "Il prezzo è 0. Proseguire?",
  "msg_feedback_confirmation": "Sei sicuro della tua valutazione? Non potrai modificarla successivamente.",
  "generic.btn_confirm_filters_label": "CONFERMA",
  "generic.btn_reset_filters_label": "RESETTA",
  "generic.all_sing_male_label": "Tutto",
  "provider.portfolio.detail.exam_duration_label": "Durata esame (Min)",
  "provider.portfolio.detail.exam_duration_label.tooltip": "Inserisci durata esame (Min)",
  "admin.providers.detail.days_filter_available_label": "Giorni filtro visualizzazione disponibilità",
  "admin.providers.detail.days_filter_available_label.tooltip": "Inserisci giorni filtro visualizzazione disponibilità",
  "admin.services.detail.img_label": "Immagine collegata",
  "admin.services.detail.img_label.tooltip": "Inserisci link immagine collegata",
  "admin.services.detail.link_wordpress_label": "Link pagina statica WordPress",
  "admin.services.detail.link_wordpress_label.tooltip": "Inserisci link pagina statica WordPress",
  "admin.services.detail.customer_descr_label": "Descrizione servizio per Cliente finale",
  "admin.services.detail.customer_descr_label.tooltip": "Inserisci descrizione servizio per Cliente finale",
  "admin.service_categories.generic.service_commission_min_amt_label": "Provvigione Provider (importo minimo)",
  "admin.service_categories.generic.service_commission_min_amt_label.tooltip": "Inserisci l'importo minimo di provvigione per il Provider",
  "admin.service_categories.generic.service_commission_percent_label": "Provvigione Provider (percentuale)",
  "admin.service_categories.generic.service_commission_percent_label.tooltip": "Inserisci la percentuale di provvigione per il Provider",
  "admin.service_categories.generic.service_pos_commission_min_amt_label": "Provvigione Punto Viasana (importo minimo)",
  "admin.service_categories.generic.service_pos_commission_min_amt_label.tooltip": "Inserisci l'importo minimo di provvigione per il Punto Viasana",
  "admin.service_categories.generic.service_pos_commission_percent_label": "Provvigione Punto Viasana (percentuale)",
  "admin.service_categories.generic.service_pos_commission_percent_label.tooltip": "Inserisci la percentuale di provvigione per il Punto Viasana",
  "admin.pos_categories.detail.internal_code_label": "Codice interno",
  "admin.pos_categories.detail.internal_code_label.tooltip": "Inserisci codice interno",
  "admin.pos_categories.detail.title": "Dettaglio tipologia Punto Viasana",
  "admin.pos.list.title": "Elenco Punti Viasana",
  "admin.pos.list.btn_pos_candicacies_label": "APPROVA CANDIDATURE Punti Viasana",
  "admin.pos.list.btn_pos_categories_label": "GESTISCI TIPOLOGIE Punti Viasana",
  "admin.pos.list.btn_import_pos_label": "IMPORT MASSIVO",
  "admin.pos.detail.title": "Dettaglio Punto Viasana",
  "pos.profile.networks_label": "Network",
  "pos.profile.networks_label.tooltip": "Inserisci network",
  "admin.pos.detail.btn_feedback_customer.label": "FEEDBACK AGGREGATI PER CUSTOMER",
  "admin.pos.detail.btn_orders_pos.label": "STORICO ORDINI Punti Viasana",
  "admin.pos.detail.btn_customer_orders.subtitle": "TUTTI GLI ACQUISTI FATTI DA CUSTOMER",
  "admin.pos.detail.btn_pos_payments_status.label": "STATO PAGAMENTI Punti Viasana",
  "admin.pos.detail.btn_pos_payments_status.subtitle": "NEI CONFRONTI DI VIASANA",
  "admin.pos.detail.btn_pos_purchases.label": "STORICO ACQUISTI Punti Viasana",
  "admin.pos.detail.btn_pos_purchases.subtitle": "TUTTI GLI ACQUISTI FATTI DA PROVIDER",
  "admin.pos.detail.btn_calendar.label": "VAI AL CALENDARIO",
  "admin.pos.detail.btn_pos_services.label": "VAI AI SERVIZI Punti Viasana",
  "admin.pos.detail.categories_label": "Tipologie",
  "admin.pos.detail.categories_label.tooltip": "Inserisci tipologie",
  "admin.candidacies.detail.categories_label": "Tipologie",
  "admin.candidacies.detail.waiting_approval_label": "Da approvare",
  "admin.candidacies.detail.rejected_label": "Respinto",
  "admin.candidacies.detail.approved_label": "Approvato",
  "admin.candidacies.pos.list.title": "Candidature Punti Viasana",
  "generic.all_plur_male_label": "Archivio",
  "generic.btn_only_active_label": "Attuali",
  "admin.candidacies.pos.detail.title": "Dettaglio candidatura Punto Viasana",
  "admin.candidacies.detail.status_candidacy_label": "Stato candidatura",
  "admin.candidacies.detail.status_candidacy_label.tooltip": "Seleziona stato candidatura",
  "provider.technicians.detail.services_label": "Servizi erogati",
  "provider.technicians.detail.title": "Dettaglio tecnico",
  "provider.technicians.detail.btn_delete_tech_label": "CANCELLA TECNICO",
  "generic.choose_excel_file_label": "Seleziona il file Excel",
  "generic.choose_attachment_file_label": "Seleziona il file da caricare",
  "admin.pos.import_modal.title": "Import massivo",
  "generic.pos": "POS",
  "generic.upload_label": "Carica",
  "generic.import_success": "Import concluso",
  "generic.inserted": "Inseriti",
  "generic.not_inserted": "Non inseriti",
  "generic.coupon":"Utilizzato",
  "generic.not_coupon": "Non utilizzato",
  "generic.Configured":"Configurati",
  "generic.summary":"Riepilogo e acquisto",
  "generic.recurring.excluded_day":"Giorno escluso dal periodo scelto",
  "generic.recurring.day_with_events":"Giorno in cui eroghi il servizio",
  "generic.recurring.empty_day":"Giorno in cui non eroghi il servizio",
  "calendar.day_with_events":"Nessun Servizio programmato in questo giorno",
  "calendar.empty_day":"Hai altri Servizi programmati in questo giorno ",
  "dashboard_calendar.day_with_events":"Nessun acquisto effettuato in questo giorno",
  "dashboard_calendar.empty_day":"Ci sono acquisti effettuati in questo giorno",
  "generic.text_calendar":"In questa giornata hai altri servizi in programma",
  "provider.technicians.detail.regions_label": "Regioni di copertura",
  "provider.technicians.detail.provinces_label": "Province di copertura",
  "provider.technicians.detail.cities_label": "Città di copertura",
  "provider.technicians.detail.email_label": "Email",
  "provider.technicians.detail.email_label.tooltip": "Inserisci email per associare o inserire un nuovo tecnico",
  "pos.shop.for.your.customers.title":"ACQUISTA PER UN TUO CLIENTE",
  "pos.shop.for.your.customers.subtitle":"In questa pagina hai la possibilità di prenotare e acquistare un Servizio per un tuo cliente, qualora l’acquisto non sia stato fatto su ViaSana e il cliente si sia presentato direttamente.",
  "pos.calendar.page.shop.for.your.customers.step01.title":"Scegli il Servizio",
  "pos.calendar.page.shop.for.your.customers.step01.subtitle":"Scegli il servizio selezionandolo tra quelli presenti qui sotto e clicca sul pulsante “Avanti”.",
  "pos.calendar.page.shop.for.your.customers.step02.title":"Scegli il Giorno",
  "pos.calendar.page.shop.for.your.customers.step02.subtitle":"Scegli il giorno dell’erogazione del Servizio selezionandolo una delle caselle colorate qui sotto. <br/>Tieni conto che le caselle rosa sono quelle corrispondenti ai giorni in cui non hai nessun Servizio in programma, mentre quelle arancioni corrispondono ai giorni in cui hai altri Servizi da erogare.",
  "pos.shop.for.your.customers.step03.title":"Scegli lo slot orario",
  "pos.shop.for.your.customers.step03.subtitle":"Scegli lo spazio orario in cui erogare il Servizio che stai acquistando per il tuo cliente.",
  "pos.shop.for.your.customers.step04.title":"Conferma",
  "pos.shop.for.your.customers.step04.subtitle":"Verifica i dati, aggiungi eventuali note relative all’acquisto per questo cliente e clicca su “Acquista”.",
  "pos.shop.for.your.customers.step05.title":"Riepilogo",
  "pos.shop.for.your.customers.step05.subtitle":"Questo il riepilogo con i dati relativi all’acquisto del servizio per il tuo cliente. Se è tutto corretto procedi cliccando su “Conferma”. Altrimenti “Torna Indietro”.",
  "pos.calendar.month_view.title": "Calendario",
  "pos.calendar.month_view.subtitle": "Lorem ipsum pos.calendar.month_view.subtitle",
  "pos.portfolio.list.title": "LA TUA VETRINA",
  "pos.portfolio.list.subtitle": "In questa pagina trovi l’elenco di tutti i servizi che stai proponendo ai tuoi clienti. <br/> Puoi consultare le informazioni di dettaglio di ogni servizio, verificarle e modificarne alcuni parametri.",
  "pos.portfolio.list.name.infoIcon":"Inserisci qui il nome del servizio per cercarlo nell’elenco sottostante",
  "pos.portfolio.list.provider_owner_name.infoIcon":"Seleziona il fornitore per visualizzare l’elenco dei relativi servizi presenti nella tua proposta",
  "pos.portfolio.list.status.infoIcon":"Visualizza solo i servizi presenti nella tua Vetrina o solo quelli ancora in Bozza",
  "pos.portfolio.detail.service_categories_label": "Categorie servizio",
  "pos.portfolio.detail.provider_owner_name_label": "Erogato da",
  "pos.portfolio.detail.config_status_label": "Stato configurazione",
  "pos.portfolio.detail.config_status_not_complete_label": "Non completo",
  "pos.portfolio.detail.config_status_complete_label": "Completo",
  "pos.portfolio.detail.active_status_label": "Stato pubblicazione",
  "pos.portfolio.detail.service_date_label": "Data pianificata",
  "pos.portfolio.detail.selling_price_label": "Prezzo vendita (€)",
  "pos.portfolio.list.btn_add_provider_service_label": "AGGIUNGI SERVIZIO DA PROVIDER",
  "pos.portfolio.list.btn_add_self_services_label": "SERVIZI IN AUTONOMIA",
  "pos.portfolio.list.btn_add_service_label": "ANAGRAFICA SERVIZI",
  "pos.portfolio.list.btn_add_single_service_label": "AGGIUNGI IL TUO SERVIZIO",
  "pos.portfolio.list.btn_add_recursive_service_label": "AGGIUNGI UN SERVIZIO RICORRENTE",
  "pos.portfolio.list.empty_message": "Non ci sono servizi programmati. Aggiungine uno con i tasti sopra.",
  "pos.portfolio.list.col_service_date_recursive_label": "Ricorrente",
  "pos.portfolio.list.col_service_date_recursive_da": "Da",
  "pos.portfolio.list.col_service_date_recursive_a": "A",
  "pos.portfolio.add_provider_service.title": "ACQUISTA SERVIZI DA FORNITORI",
  "pos.portfolio.add_provider_service.subtitle": "In questa area puoi acquistare servizi organizzati e gestiti da fornitori esterni, e aggiungerli alla tua proposta per i tuoi clienti.<br/> Avrai la possibilità di scegliere il Servizio, il giorno di erogazione, gli orari e il prezzo da proporre ai tuoi clienti.",
  "pos.portfolio.add_provider_service.step1.wizard.title":"Servizio",
  "pos.portfolio.add_provider_service.Choose.Step.title":"Scegli il Servizio",
  "pos.portfolio.add_provider_service.Choose.Step.subtitle":"Seleziona il Servizio che vuoi aggiungere alla tua proposta tra quelli elencati sotto.",
  "pos.portfolio.add_provider_service.step2.wizard.title":"Provider",
  "pos.portfolio.add_provider_service.Provider.Step.title":"Scegli il fornitore",
  "pos.portfolio.add_provider_service.Provider.Step.subtitle":"Seleziona il fornitore tra quelli che vedi qui sotto per accedere al calendario delle sue disponibilità relative alla fornitura di questo Servizio.<br/> Puoi avere maggiori informazioni sui fornitori cliccando su “Descrizione” e visualizzando i file scaricabili quando sono disponibili.",
  "pos.portfolio.add_provider_service.step3.wizard.title":"Data",
  "pos.portfolio.add_provider_service.Calendar.Step.title":"Scegli il giorno",
  "pos.portfolio.add_provider_service.Calendar.Step.subtitle":"Seleziona il giorno dal calendario qui sotto in cui vuoi proporre il Servizio scelto.<br/>I giorni disponibili sono quelli in verde.",
  "pos.portfolio.add_provider_service.step4.wizard.title":"Codice Coupon",
  "pos.portfolio.add_provider_service.Coupon.Step.title":"Utilizza il tuo Coupon di rimborso",
  "pos.portfolio.add_provider_service.Coupon.Step.subtitle":"Sei hai diritto ad un rimborso vedrai il relativo Coupon qui sotto. Selezionalo e clicca su “Avanti”.<br/>La cifra del Coupon sarà scorporata dal totale che dovrai pagare per questo servizio.",
  "pos.portfolio.add_provider_service.step5.wizard.title":"Conferma",
  "pos.portfolio.add_provider_service.Summary.Step.title":"Riepilogo dati e Conferma",
  "pos.portfolio.add_provider_service.Summary.Step.subtitle":"Controlla i dati relativi al tuo ordine, se sono tutti corretti clicca su “Avanti” per effettuare il pagamento. Altrimenti clicca su “Indietro”.",
  "pos.portfolio.add_provider_service.step6.wizard.title":"Orari e costo",
  "pos.portfolio.add_provider_service.Config.Step.title":"Configura il tuo Servizio",
  "pos.portfolio.add_provider_service.Config.Step.subtitle":"In questa pagina ti vengono mostrate alcune informazioni riepilogative del servizio che stai aggiungendo.<br/>Per concludere la configurazione devi inserire l’orario di inizio e quello di fine erogazione, e infine il costo per ogni erogazione.",
  "pos.portfolio.add_provider_service.step7.wizard.title":"Slot servizi",
  "pos.portfolio.add_provider_service.Slot.Step.title":"Stabilisci la disponibilità di ogni slot orario ",
  "pos.portfolio.add_provider_service.slot.Step.subtitle":"In questa pagina puoi decidere quali slot, all’interno della giornata, vuoi mettere a disposizione per i tuoi clienti.<br/>Gli slot verdi sono quelli in cui stai proponendo l’erogazione del Servizio.<br/>Negli orari corrispondenti agli slot che escludi, e che rimangono grigi, i tuoi clienti non potranno prenotare il servizio..<br/>Clicca sugli slot per attivarli e disattivarli.<br/>Al termine clicca sul pulsante “Salva” per confermare l’aggiunta del Servizio alla tua Vetrina.",
  "pos.portfolio.add_provider_service.choose_service_step.title": "Scegli il servizio che vorresti aggiungere",
  "pos.portfolio.add_provider_service.choose_service_step.subtitle":"-	Scegli il servizio selezionandolo tra quelli presenti qui sotto.<br/>-	Imposta la durata necessaria per ogni erogazione, tenendo conto dei tempi di accoglienza del cliente.<br/>-	Inserisci una breve descrizione del servizio che sarà visibile ai potenziali clienti che accedono alla piattaforma ViaSana.",
  "pos.portfolio.add_provider_service.choose_service_step1.wizard.title": "Servizio, descrizione e durata",
  "pos.portfolio.add_provider_service.choose_service_step1b.wizard.title":"Servizio",
  "pos.portfolio.add_provider_service.choose_service_step2.wizard.title": "Allegati",
  "pos.portfolio.add_provider_service.choose_service_step3.wizard.title":"Data",
  "pos.portfolio.add_provider_service.choose_service_step3.Recursive.wizard.title":"Periodo",
  "pos.portfolio.add_provider_service.choose_service_step4.wizard.title":"Orario e costo",
  "pos.portfolio.add_provider_service.choose_service_step4.Recursive.wizard.title":"Slot servizi",
  "pos.portfolio.add_provider_service.choose_service_step5.Recursive.wizard.title":"Costi e eccezioni",
  "pos.portfolio.add_provider_service.choose_service_step.col_name_label": "Elenco servizi",
  "pos.portfolio.add_provider_service.choose_service_step.col_description_label": "Descrizione",
  "pos.portfolio.add_provider_service.choose_service_step.empty_message": "Non ci sono servizi disponibili",
  "pos.portfolio.add_provider_service.service_selected_step.attachments_label": "Allegati",
  "pos.portfolio.add_provider_service.provider_service_selected_step.empty_message": "Non ci sono provider disponibili",
  "pos.portfolio.add_provider_service.provider_service_selected_step.attachments_label": "Allegati Provider",
  "pos.portfolio.add_provider_service.provider_service_selected_step.Provider.empty_attachments_label":"Non ci sono allegati Provider associati a questo servizio",
  "pos.portfolio.add_provider_service.provider_service_selected_step.empty_attachments_label":"Non ci sono allegati associati a questo servizio",
  "pos.portfolio.add_provider_service.choose_coupon_step.title": "Scegli o ricerca il coupon che vorresti applicare",
  "pos.portfolio.add_provider_service.choose_coupon_step.col_name_label": "Elenco coupon",
  "pos.portfolio.add_provider_service.choose_coupon_step.col_description_label": "Descrizione",
  "pos.portfolio.add_provider_service.choose_coupon_step.coupon_code.label":"Codice Coupon",
  "pos.portfolio.add_provider_service.choose_coupon_step.coupon_code.label.tooltip":"Inserisci il codice coupon da ricercare",
  "pos.portfolio.add_provider_service.choose_coupon_step.error.coupon_not_valid": "Codice Coupon non valido",
  "pos.portfolio.add_provider_service.choose_provider_step.title": "Seleziona un Provider per vedere la sua agenda",
  "pos.portfolio.add_provider_service.choose_provider_step.col_name_label": "I Provider della zona",
  "pos.portfolio.add_provider_service.choose_provider_step.col_cost_ex_vat_label": "Prezzo esposto (iva esclusa)",
  "pos.portfolio.add_provider_service.choose_provider_step.col_description_label": "Descrizione Provider",
  "pos.portfolio.add_provider_service.choose_provider_step.col_duration_label": "Durata Esame",
  "pos.portfolio.add_provider_service.choose_provider_step.col_timeframe_label": "Orario",
  "pos.portfolio.add_provider_service.choose_provider_step.col_attachments_label": "File da scaricare",
  "pos.portfolio.add_provider_service.provider_calendar_step.attachments_label": "Allegati",
  "pos.portfolio.add_provider_service.provider_calendar_step.calendar_text": "Lorem ipsum pos.portfolio.add_provider_service.provider_calendar_step.calendar_text",
  "pos.portfolio.add_provider_service.provider_calendar_step.calendar_cell_free_label": "Nessun servizio",
  "pos.portfolio.add_provider_service.provider_summary_step.title": "Riepilogo",
  "pos.portfolio.add_provider_service.provider_summary_step.service_label": "Servizio",
  "pos.portfolio.add_provider_service.provider_summary_step.provider_label": "Provider",
  "pos.portfolio.add_provider_service.provider_summary_step.date_label": "Data",
  "pos.portfolio.add_provider_service.provider_summary_step.coupon_label":"Coupon",
  "pos.portfolio.add_provider_service.provider_summary_step.coupon_value":"Applicato coupon {coupon} del valore di:",
  "pos.portfolio.add_provider_service.provider_summary_step.no_coupon_value":"(senza coupon: ",
  "pos.portfolio.add_provider_service.provider_summary_step.cost_ex_iva_label": "Costo (iva esclusa)",
  "pos.portfolio.add_provider_service.provider_summary_step.cost_incl_iva_label": "Costo (iva inclusa)",
  "pos.portfolio.add_provider_service.payment_step.btn_next_label": "Conferma e paga",
  "pos.portfolio.add_provider_service.payment_result_step.btn_configure_service_label": "CONFIGURA IL SERVIZIO",
  "pos.portfolio.edit.configure_service.title": "Configura il tuo servizio",
  "pos.portfolio.edit.configure_service.subtitle": "Lorem ipsum pos.portfolio.edit.configure_service.subtitle",
  "pos.portfolio.edit.configure_service.btn_next_label": "CONTINUA",
  "pos.portfolio.edit.configure_service.btn_save_label": "SALVA",
  "btn_description_label":"descrizione",
  "pos.portfolio.edit.configure_service.info_label": "Info",
  "pos.portfolio.edit.configure_service.info": "In questa pagina puoi modificare:<br/>- gli orari di inizio e fine erogazione del servizio<br/>- il costo per ogni erogazione <br/>- decidere se mettere il servizio nella tua vetrina o se lasciarlo in bozza per una pubblicazione successiva.<br/> Se il servizio ha una programmazione ricorrente, non sarà possibile modificare gli orari generali di inizio e fine erogazione, ma sarà invece possibile definire gli orari dei singoli giorni cliccando sul pulsante “Gestisci eccezioni”.",
  "pos.portfolio.edit.time.slot.configure_service.info":"In questa pagina puoi decidere quali slot, all’interno della giornata, vuoi mettere a disposizione per i tuoi clienti.<br/> Negli orari corrispondenti agli slot che escludi, e che rimangono grigi, i tuoi clienti non potranno prenotare il servizio.<br/>Clicca sugli slot per attivarli e disattivarli.",
  "pos.portfolio.edit.configure_service.config_label": "Dati di configurazione",
  "pos.portfolio.edit.configure_service.info.name_label": "Nome",
  "pos.portfolio.edit.configure_service.info.description_label": "Descrizione",
  "pos.portfolio.edit.configure_service.info.service_cats_label": "Categorie servizio",
  "pos.portfolio.edit.configure_service.info.provided_by_label": "Erogato da",
  "pos.portfolio.edit.configure_service.info.status_label": "Stato",
  "pos.portfolio.edit.configure_service.info.attachments_label": "Allegati",
  "pos.portfolio.edit.configure_service.info.no_attachment_label": "Nessun allegato inserito",
  "pos.portfolio.edit.configure_service.info.no_attachments_Provider_label": "Nessun allegato inserito",
  "pos.portfolio.edit.configure_service.info.provider_attachments_label": "Allegati Provider",
  "pos.portfolio.edit.configure_service.info.exam_attachments_label":"Come prepararsi all'esame",
  "pos.portfolio.edit.configure_service.config.day_label": "Giornata",
  "pos.portfolio.edit.configure_service.config.time_valid_from_label": "Valido da (ora)",
  "pos.portfolio.edit.configure_service.config.time_valid_from.tooltip": "Seleziona l’orario di inizio erogazione del servizio.",
  "pos.portfolio.edit.configure_service.config.time_valid_to_label": "Valido fino a (ora)",
  "pos.portfolio.edit.configure_service.config.time_valid_to.tooltip": "Seleziona l’orario di fine erogazione del servizio.",
  "pos.portfolio.edit.configure_service.config.duration_label": "Durata esame",
  "pos.portfolio.edit.configure_service.config.duration_min_label": "min",
  "pos.portfolio.edit.configure_service.config.cost_vat_excl_label": "Costo della giornata (iva esclusa)",
  "pos.portfolio.edit.configure_service.config.cost_vat_incl_label": "Costo della giornata (iva inclusa)",
  "pos.portfolio.edit.configure_service.config.iva_label": "IVA (da admin)",
  "pos.portfolio.edit.configure_service.config.price_incl_iva_label": "Prezzo al pubblico (IVA compresa)",
  "pos.portfolio.edit.configure_service.config.price_incl_iva.tooltip": "Definisci un costo per ogni erogazione di questo servizio.",
  "pos.portfolio.edit.configure_service.config.status_label": "Stato",
  "pos.portfolio.edit.configure_service.config.status.tooltip": "Decidi se vuoi che questo servizio sia visualizzato al pubblico, oppure se vuoi tenerlo in bozza e inserirlo nella tua vetrina successivamente.",
  "pos.portfolio.edit.configure_service.exams_deliverable_label": "a disposizione",
  "pos.portfolio.edit.configure_service.exams_selected_label":"selezionati su",
  "pos.portfolio.edit.configure_service.occurrence_label": "Occorrenza",
  "pos.portfolio.edit.configure_service.occurrence_recursive_label": "Ricorrente",
  "pos.portfolio.edit.configure_service.occurrence_single_label": "Puntuale",
  "pos.portfolio.edit.configure_service.date_valid_from_label": "Valido da",
  "pos.portfolio.edit.configure_service.date_valid_to_label": "Valido fino a",
  "pos.portfolio.edit.configure_service.weekdays_label": "Giornate",
  "pos.portfolio.edit.configure_service.excluded_dates_label": "Giornate escluse",
  "pos.portfolio.edit.income.title": "Fino a quanto puoi ricavare?",
  "pos.portfolio.edit.income.subtitle": "In base ai {slots} slot disponibili all'interno della facia oraria selezionata e al prezzo per singolo esame, il tuo potenziale ricavo per questa giornata è di:",
  "pos.services.add.title": "Crea Servizio",
  "pos.services.add.subtitle": "Lorem ipsum pos.services.add.subtitle",
  "pos.services.add.choose_service_step.title": "Scegli il servizio che vorresti aggiungere",
  "pos.services.add.choose_service_step.service_name_label": "Servizi disponibili",
  "pos.services.add.choose_service_step.service_name_label.tooltip": "Scegli da questo elenco il Servizio che vuoi aggiungere alla tua vetrina.",
  "pos.services.add.choose_service_step.duration_label": "Durata in minuti",
  "pos.services.add.choose_service_step.duration_label.tooltip": "Seleziona la durata necessaria per l’erogazione completa di questo servizio.",
  "pos.portfolio.edit.service_selected.duration_label": "{duration} minuti",
  "pos.services.add.description_step.title": "Descrizione completa",
  "pos.services.add.description_step.description_label": "Descrizione completa",
  "pos.services.add.description_step.description_label.tooltip": "Inserisci una breve descrizione del servizio che proponi ai tuoi potenziali clienti.",
  "pos.portfolio.add_provider_service.attachments.title":"Carica gli allegati",
  "pos.portfolio.add_provider_service.attachments.subtitle":"Qui hai la possibilità di caricare uno o più file come allegati da mettere a disposizione dei clienti per offrire approfondimenti in merito al Servizio proposto. Gli allegati saranno visibili nella scheda del relativo Servizio della tua area su ViaSana. Puoi caricare file nei formati XXX . Clicca su “Sfoglia” per scegliere il file presente nel tuo dispositivo.<br/>Quando hai terminato di caricare gli allegati clicca sul pulsante “Avanti”.<br/>Se non carichi nessun allegato clicca sul pulsante “Salta”.",
  "pos.portfolio.add_provider_service.exam_attachments.title":"Come prepararsi agli esami",
  "pos.portfolio.add_provider_service.Calendar.title":"Scegli la giornata",
  "pos.portfolio.add_provider_service.Calendar.subtitle":"Clicca sul giorno in cui vuoi proporre il tuo Servizio all’interno di questo calendario.<br/>Le caselle verdi sono quelle corrispondenti ai giorni in cui non stai proponendo nessun servizio. Le caselle arancioni sono quelle in cui hai altri Servizi in programmazione.",
  "pos.portfolio.add_provider_service.Recursive.Calendar.title":"Scegli la giornata",
  "pos.portfolio.add_provider_service.Recursive.Calendar.subtitle":"Qui puoi definire il periodo in cui vuoi erogare il Servizio, i giorni della settimana ed eventuali eccezioni.<br/>L’intervallo di tempo massimo programmabile è di due mesi.<br/>Seleziona l’intervallo temporale attraverso i due campi qui sotto.",
  "pos.portfolio.add_provider_service.Recursive.Calendar.make_the_service_available":"Rendi disponibile il Servizio in questa giornata",
  "pos.portfolio.add_provider_service.Recursive.Calendar.do_not_provide_the_service":"Non erogare il Servizio in questa giornata",
  "pos.portfolio.add_provider_service.Recursive.Time.Slot.title":"Stabilisci la disponibilità di ogni slot orario per ogni giorno della settimana",
  "pos.portfolio.add_provider_service.Recursive.Time.Slot.subtitle":"Qui puoi definire gli slot orari che vuoi mettere a disposizione per i tuoi clienti per ogni giorno della settimana all’interno del periodo precedentemente scelto. Ad esempio: tutti i lunedì delle settimane, tutti i  martedì, etc. <br/>Gli slot verdi sono quelli in cui stai proponendo l’erogazione del Servizio. Negli orari corrispondenti agli slot che escludi, e che rimangono grigi, i tuoi clienti non potranno prenotare il servizio.<br/>Successivamente potrai stabilire delle eccezioni su ogni singolo giorno del periodo.<br/>Al termine clicca sul pulsante “Avanti” per proseguire con la configurazione del Servizio.",
  "pos.portfolio.add_provider_service.Single.Time.Slot.title":"Stabilisci la disponibilità di ogni slot orario",
  "pos.portfolio.add_provider_service.Single.Time.Slot.subtitle":"In questa pagina puoi decidere quali slot, all’interno della giornata, vuoi mettere a disposizione per i tuoi clienti.<br/> Gli slot verdi sono quelli in cui stai proponendo l’erogazione del Servizio.<br/>Negli orari corrispondenti agli slot che escludi, e che rimangono grigi, i tuoi clienti non potranno prenotare il servizio.<br/>Clicca sugli slot per attivarli e disattivarli.<br/>Al termine clicca sul pulsante “Salva” per confermare l’aggiunta del Servizio alla tua Vetrina.",
  "calendar.slot.deactivated":"Rendi disponibile questo slot per i tuoi clienti",
  "calendar.slot.activated":"Escludi questo slot dalla tua proposta giornaliera.",
  "pos.services.add.attachments_step.title": "Caricamento allegati",
  "pos.services.add.attachments_step.uploader_label": "Allegati",
  "pos.portfolio.add_provider_service.time.and.price.title":"Configura il tuo Servizio",
  "pos.portfolio.add_provider_service.time.and.price.subtitle":"In questa pagina ti vengono mostrate alcune informazioni riepilogative del servizio che stai aggiungendo.<br/>Per concludere la configurazione devi inserire l’orario di inizio e quello di fine erogazione, e infine il costo per ogni erogazione.",
  "pos.portfolio.add_provider_service.price.and.exception.title":"Definisci il prezzo ed eventuali eccezioni",
  "pos.portfolio.add_provider_service.price.and.exception.subtitle":"In questa pagina ti vengono mostrate alcune informazioni riepilogative del servizio che stai aggiungendo.<br/>Per concludere la configurazione devi inserire il costo per ogni erogazione.<br/>Più sotto troverai una funzione che ti consente di stabilire delle eccezioni su singole giornate del periodo.",
  "pos.portfolio.add_recursive_service.calendar_step.btn_show_label.title":"Definisci le eccezioni",
  "pos.portfolio.add_recursive_service.calendar_step.btn_show_label.subtitle":"In questa pagina puoi scorrere tra tutti i singoli giorni del periodo scelto per definire per ogni giornata gli slot orari per l’erogazione del Servizio.<br/>Non puoi erogare il servizio in determinati orari di determinati giorni? Clicca le freccine per visualizzare i giorni e poter agire sui singoli slot. Al termine della configurazione clicca su “Salva” per aggiungere il servizio alla tua vetrina.",
  "pos.services.add.summary_step.title": "Riepilogo",
  "pos.services.add.summary_step.attachments_label": "Allegati",
  "pos.services.list.title": "Servizi",
  "pos.services.list.subtitle": "Lorem ipsum pos.services.list.subtitle",
  "pos.services.list.duration_col_label": "Durata (min)",
  "pos.portfolio.add_single_service.title": "AGGIUNGI UN TUO SERVIZIO PERSONALIZZATO",
  "pos.portfolio.add_single_service.subtitle": "Aggiungi alla tua vetrina uno dei tuoi servizi personalizzati da erogare all’interno di una singola giornata.",
  "pos.portfolio.add_single_service.choose_service_step.title": "Scegli il servizio che vorresti aggiungere",
  "pos.portfolio.add_single_service.choose_service_step.col_name_label": "Elenco servizi",
  "pos.portfolio.add_single_service.choose_service_step.col_description_label": "Descrizione",
  "pos.portfolio.add_single_service.calendar_step.title": "Seleziona gli slot",
  "pos.portfolio.add_recursive_service.title": "AGGIUNGI UN TUO SERVIZIO RICORRENTE",
  "pos.portfolio.add_recursive_service.subtitle": "Aggiungi alla tua vetrina uno dei tuoi servizi personalizzati e rendilo ricorrente su più giornate.",
  "pos.portfolio.add_recursive_service.choose_service_step.title": "Scegli il Servizio",
  "pos.portfolio.add_recursive_service.choose_service_step.service_name_col": "Elenco servizi",
  "pos.portfolio.add_recursive_service.choose_service_step.description_col": "Descrizione",
  "pos.portfolio.add_recursive_service.calendar_step.title": "Seleziona l'intervallo temporale di validità del servizio",
  "pos.portfolio.add_recursive_service.calendar_step.date_from_label": "Dal Giorno",
  "pos.portfolio.add_recursive_service.calendar_step.date_from_label.info": "Selezionare il giorno di inizio del periodo.",
  "pos.portfolio.add_recursive_service.calendar_step.date_to_label": "Al Giorno",
  "pos.portfolio.add_recursive_service.calendar_step.date_to_label.info": "Selezionare il giorno di fine del periodo.",
  "pos.portfolio.add_recursive_service.calendar_step.weekdays_label": "Giorni della settimana in cui erogare il servizio",
  "pos.portfolio.add_recursive_service.calendar_step.weekdays_label.info": "Dopo aver impostato il periodo di tempo, selezionare i giorni della settimana in cui si intende erogare il servizio.",
  "pos.portfolio.add_recursive_service.calendar_step.exclued_dates_label": "Giornate escluse dal periodo",
  "pos.portfolio.add_recursive_service.calendar_step.exclued_dates_label.info": "Utilizza il calendario sottostante per eventualmente escludere delle giornate dal periodo di tempo indicato cliccando il giorno.",
  "pos.portfolio.add_recursive_service.calendar_step.exclued_dates_label.subtitle":"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  "pos.portfolio.add_recursive_service.calendar_step.btn_show_label": "Gestisci Eccezioni",
  "pos.portfolio.add_recursive_service.calendar_step.btn_show.subtitle":"Definisci eventuali eccezioni per ogni singolo giorno all’interno del periodo scelto. Puoi togliere slot orari nel caso in cui tu non abbia la possibilità di gestire il servizio in determinate giornate e orari.",
  "pos.portfolio.add_recursive_service.calendar_step.btn_hide_label": "Nascondi Eccezioni",
  "pos.portfolio.add_recursive_service.time_slots_step.title": "Seleziona giorni e fasce orarie degli slot",
  "pos.portfolio.add_recursive_service.time_slots_step.subtitle": "Lorem ipsum pos.portfolio.add_recursive_service.time_slots_step.subtitle",
  "pos.portfolio.add_recursive_service.time_slots_step.btn_apply_all_label": "Applica a tutti",
  "pos.portfolio.time_slot.simultaneous.title": "Altri servizi erogati nella fascia oraria",
  "weekdays_short.1": "LUN",
  "weekdays_short.2": "MAR",
  "weekdays_short.3": "MER",
  "weekdays_short.4": "GIO",
  "weekdays_short.5": "VEN",
  "weekdays_short.6": "SAB",
  "weekdays_short.7": "DOM",
  "weekdays_long.1": "LUNEDÌ",
  "weekdays_long.2": "MARTEDÌ",
  "weekdays_long.3": "MERCOLEDÌ",
  "weekdays_long.4": "GIOVEDÌ",
  "weekdays_long.5": "VENERDÌ",
  "weekdays_long.6": "SABATO",
  "weekdays_long.7": "DOMENICA",
  "calendar.month_view.title": "Calendario",
  "calendar.month_view.subtitle": "Aggregato per tecnici e servizi",
  "calendar.month_view.event.title": "Già acquistati",
  "calendar.day_view.title": "Il tuo calendario dei servizi",
  "calendar.day_view.subtitle": "Qui puoi visualizzare il programma giornaliero di ogni singolo servizio distribuito per slot temporali. <br />Ogni slot è lo spazio temporale, definito in precedenza, in cui ogni singolo servizio può essere erogato nell’arco della giornata. <br/>Puoi vedere quali sono gli slot già occupati da una prenotazione, annullarla e anche decidere di aggiungere o togliere la disponibilità per ogni slot.",
  "planned_service.title": "Modifica Servizio",
  "planned_service.confirm": "Conferma",
  "orders.list.tab_title": "Ordini",
  "orders.list.title": "Ordini effettuati",
  "orders.list.subtitle": "Lorem orders.list.subtitle",
  "orders.list.empty_message": "Non ci sono ordini da vedere",
  "admin.orders.list.title": "Ordini effettuati dai Punti Viasana",
  "admin.orders.list.subtitle": "Lorem admin.orders.list.subtitle",
  "admin.canceled-orders.list.title": "Ordini annullati dai Punti Viasana",
  "admin.canceled-orders.list.subtitle": "Lorem admin.canceled-orders.list.subtitle",
  "admin.provider-canceled-orders.list.title": "Ordini annullati dai Provider",
  "admin.provider-canceled-orders.list.subtitle": "Lorem admin.provider-canceled-orders.list.subtitle",
  "customer-orders.list.title": "LE TUE VENDITE",
  "customer-orders.list.subtitle": "Storico e dettaglio dei servizi acquistati dai tuoi clienti",
  "customer-orders.subtitle":"In questa pagina vedi l’elenco di tutti gli ordini che hanno effettuato i tuoi clienti.<br/>Clicca sull’icona dell’occhio per visualizzare i dettagli dell’ordine corrispondente.",
  "customer-orders.title":"Elenco ordini",
  "customer-orders.code":"Se conosci il codice dell’Ordine ricevuto inseriscilo qui e cercalo in questo elenco.",
  "customer-orders.pos-services":"Seleziona il Servizio di cui ti interessa vedere in elenco gli ordini effettuati dai tuoi clienti.",
  "customer-orders.calendar":"Seleziona il giorno di cui ti interessa visualizzare i Servizi acquistati dai tuoi clienti",
  "customer-orders.detail.title":"Informazioni di dettaglio del dell’Ordine {code}",
  "customer-orders.detail.subtitle":"Stai vedendo tutte le informazioni relative all’ordine selezionato.",
  "pos-orders.list.title":"I TUOI ACQUISTI",
  "pos-orders.list.subtitle":"Storico e dettaglio dei servizi che hai acquistato da fornitori",
  "pos.order_code.value":"Se conosci il codice dell’Ordine effettuato inseriscilo qui e cercalo in questo elenco",
  "pos.order_pos.service":"Seleziona il Servizio di cui ti interessa vedere gli ordini che hai effettuato dal fornitore",
  "pos.order_pos.date":"Seleziona il giorno di cui ti interessa visualizzare i Servizi che hai acquistato dal fornitore",
  "pos-orders.title":"Elenco acquisti",
  "pos-orders.subtitle":"In quest’area puoi visualizzare l’elenco e il dettaglio di tutti i tuoi acquisti relativi a Servizi presso i provider.<br/>Nella pagina di dettaglio di ogni ordine, oltre a vedere tutte le relative informazioni, puoi anche dare una valutazione del servizio.",
  "pos-orders.list.deteils.title":"Informazioni di dettaglio dell’Ordine {code}",
  "pos-orders.list.deteils.subtitle":"Stai vedendo tutte le informazioni relative all’ordine selezionato. <br/>Puoi valutare il Servizio acquistato attraverso un punteggio da uno a cinque cliccando sulla stellina corrispondente alla tua valutazione.<br/>Esprimi la tua valutazione per questo servizio acquistato.<br/>Puoi scegliere di valutare il Servizio con un punteggio da 1 a 5. Clicca sulla stellina corrispondente al punteggio che vuoi dare. Se ad esempio vuoi dare una valutazione di 4 su 5, allora clicca la quarta stellina.",
  "admin.customer-orders.list.title": "Ordini effettuati ai Punti Viasana dai Clienti",
  "orders.table.id": "ID",
  "orders.table.order_code": "Codice Ordine",
  "orders.table.purchased_service": "Servizio Acquistato",
  "orders.table.pos": "Punti Viasana",
  "orders.table.provider": "Provider",
  "orders.table.purchase_date": "Data Acquisto",
  "orders.table.cancelation_date": "Data Annullamento",
  "orders.table.techician": "Tecnico",
  "orders.table.taxable": "Imponibile",
  "orders.table.amount": "Importo (IVA)",
  "orders.table.coupon_code": "Coupon",
  "orders.table.taxable_to_pay": "Imp.le da pagare (no IVA)",
  "orders.table.taxable_paid": "Imp.le pagato (IVA)",
  "orders.table.service_time_slot_from_at": "Ora di inizio",
  "orders.table.service_time_slot_to_at": "Ora di fine",
  "orders.table.status": "Stato",
  "orders.details.title": "Dettaglio Ordine",
  "orders.details.subtitle": "Dettaglio Ordine Lorem orders.details.subtitle",
  "orders.details.tab_title": "Dettaglio Ordine",
  "customer-orders.details.title": "Dettaglio Ordine Cliente",
  "customer-orders.details.subtitle": "Dettaglio Ordine Cliente Lorem customer-orders.details.subtitle",
  "customer-orders.details.tab_title": "Dettaglio Ordine Cliente",
  "customer.no.coupon.has.been.used": "Non è stato utilizzato nessun coupon",
  "customer.there.are.no.notes": "Non ci sono note",
  "customer.anonymous.customer": "Cliente anonimo",
  "customers.list.tab_title": "Clienti",
  "customers.list.title": "Elenco Clienti",
  "customers.table.type": "Tipologia Punti Viasana",
  "customers.table.business_name": "Ragione Sociale Punto Viasana",
  "customers.table.orders_count": "N° Ordini",
  "technicians.details.title": "Dettaglio Tecnico",
  "btn_go_to_history": "Storico Giornate",
  "btn_go_to_calendar": "Vai al Calendario",
  "btn_detail": "VEDI DETTAGLIO",
  "btn_cancel": "ANNULLA",
  "pos.calendar_day.delete_service": "ELIMINA SERVIZIO",
  "btn_check_label": "Ricerca",
  "order_completed_success": "Acquisto eseguito",
  "pos.buy_for_customer.title": "ACQUISTA PER IL TUO CLIENTE",
  "pos.buy_for_customer.new": "Registra e acquista",
  "pos.buy_for_customer.selected": "Acquista per il Cliente selezionato",
  "pos.buy_for_custumer.buy":"Acquista ",
  "pos.buy_for_custumer.search":"Ricerca ",
  "pos.buy_for_custumer.your.archive":"nel tuo archivio",
  "pos.buy_for_custumer.a.customer":"un cliente ",
  "pos.buy_for_customer.service": "Servizio: ",
  "pos.buy_for_customer.selection":"Seleziona ",
  "pos.buy_for_customer.result":"risultati della ricerca",
  "pos.buy_for_customer.day": "Giorno: ",
  "pos.buy_for_customer.fromto": "Da/a: ",
  "pos.buy_for_customer.price": "Prezzo: ",
  "pos.buy_for_customer.name.surname":"Nome e cognome: ",
  "pos.buy_for_customer.email":"Email: ",
  "pos.buy_for_customer.cf":"Codice fiscale: ",
  "pos.buy_for_customer.province":"Provincia: ",
  "pos.buy_for_customer.city":"Città: ",
  "pos.buy_for.Customer":"Per l'utente",
  "pos.price.error":"Il prezzo non può essere nullo, si",
  "pos.buy_for_customer.error.nofieldsedited": "Compilare almeno un campo",
  "pos.buy_for_customer.error.order_existing": "Slot già acquistato",
  "pos.buy_for_customer.search.title1": " un Cliente nel tuo archivio ed ",
  "pos.buy_for_customer.search.title2": " per lui ",
  "pos.buy_for_customer.search.result-title": "Risultati della ricerca",
  "pos.buy_for_customer.search.result-error": "La ricerca non ha prodotto risultati",
  "pos.buy_for_customer.search.result-info": "un utente dai ",
  "pos.buy_for_customer.anonymous": "Cliente Anonimo",
  "pos.buy_for_customer.anonymous.title" : " il Servizio per un cliente non registrato",
  "pos.buy_for_customer.anonymous.txt.label1": "per un cliente ",
  "pos.buy_for_customer.anonymous.text":"Inserisci eventuali note nel campo sottostante e procedi con l’Acquisto.",
  "pos.buy_for_customer.anonymous.txt.label2": "nella piattaforma",
  "pos.buy_for_customer.anonymous.txtArea":"Note interne su questo acquisto",
  "pos.buy_for_customer.anonymous.Recup2":"Hai acquistato il servizio: ",
  "pos.buy_for_customer.anonymous.Recup":"Per un cliente anonimo hai acquistato il servizio: ",
  "pos.buy_for_customer.anonymous.Recup.textArea":"Con queste note: ",
  "pos.buy_for_customer.anonymous.txt.placeholder": "Inserisci le note sull'acquisto",
  "pos.buy_for_customer.newuser.title": "Registra un nuovo Cliente in Viasana",
  "pos.buy_for_customer.newuser.title2": "Registra un nuovo Cliente e acquista",
  "pos.buy_for_customer.newuser.privacy.label": "accetto le condizioni privacy Viasana",
  "pos.buy_for_customer.newuser.privacy.web.address": "https://www.viasana.it/privacy.html",
  "pos.buy_for_customer.newuser.privacy.web.label": "consultabili qui",
  "pos.buy_for_customer.newuser.terms.label": "accetto i termini del servizio Viasana",
  "pos.buy_for_customer.newuser.terms.web.address": "https://www.viasana.it/terms.html",
  "pos.buy_for_customer.newuser.terms.web.label": "consultabili qui",
  
  "pos.buy": "ACQUISTA",
  "pos.confirms":"CONFERMA",
  "pos.save_and_buy": "Registra e ACQUISTA",
  "calendar.day_view.tab_title": "Calendario",
  "order.status.ok001": "Da erogare",
  "order.status.okend": "Erogato",
  "order.status.kopos": "Annullato dal Punto Viasana",
  "order.status.koprv": "Annullato dal Provider",
  "order.status.bookd": "Prenotato",
  "order.status.expir": "Scaduto",
  "admin.technicians.table.providers": "Provider Associati",
  "admin.technicians.table.provinces": "Province di copertura",
  "admin.technicians.table.services": "Servizi erogati",
  "calendar.month_view.tab_title": "Calendario",
  "vuecal_button_label": "Vai al dettaglio",
  "provider_tech.appointements.title": "Appuntamenti",
  "provider_tech.availabilities.title": "Disponibilità",
  "provider_tech.services.title": "Servizi",
  "service_page.add_service.title": "Aggiungi servizi",
  "service_page.remove_service.msg": "Sicuro di vole rimuovere il servizio?",
  "service_page.remove_service.msg_success": "Servizio rimosso",
  "service_page.add_services.success": "Servizi aggiunti con successo",
  "service_page.title": "I tuoi servizi",
  "service_page.search_label": "Cerca servizi",
  "service_page.search_placeholder": "Cerca per nome",
  "service_page.no_results_message": "Nessun servizio trovato",
  "provider_technician.availabilities.select_provider_msg": "Seleziona un provider",
  "provider_technician.availabilities.cannot_toggle_booked_slot": "Non puoi togliere la disponibilità per una giornata già acquistata.",
  "provider_technician.availabilities.cannot_select_past_date": "Non puoi modificare una data nel passato.",
  "provider_technician.availabilities.added": "Disponibilità aggiunta",
  "provider_technician.availabilities.removed": "Disponibilità rimossa",
  "mobile.notifications.title": "Notifiche",
  "appointments_page.title": "I tuoi impegni",
  "appointments_page.provider_label": "Provider",
  "appointments_page.provider_ph": "Seleziona un provider",
  "appointments_page.bought_by": "Acquistato da ",
  "appointments_page.address": "Indirizzo",
  "appointments_page.time_slot": "Fascia oraria",
  "anonymous_purchase": "Acquistato da utente anonimo.",
  "purchased_by": "Acquistato da",
  "menu.appointments": "I tuoi impegni",
  "menu.availabilities": "Le tue disponibilità",
  "menu.services": "I tuoi servizi",
  "menu.profile": "Il tuo profilo",
  "notifications_page.title": "LE TUE NOTIFICHE",
  "last_notifications_page.title":"Ultime notifiche",
  "notifications_page.subtitle": "In questa pagina trovi l’elenco delle notifiche che hai ricevuto.",
  "availabilities_page.title": "Le tue disponibilità",
  "availabilities_page.provider_label": "Provider",
  "availabilities.legend.operating": "Operativo, <br />  0 acquisti",
  "availabilities.legend.operating_no_purchases": "Operativo, <br />  0 acquisti",
  "availabilities.legend.operating_one_purchase": "Operativo, <br /> 1 acquisto",
  "availabilities.legend.operating_other_provider": "Operativo, <br /> Altro provider",
  "common.cancel": "Annulla",
  "common.confirm": "Conferma",
  "nexi.error": "Errore Nexi",
  "nexi.result": "Pagamento Annullato - {esito}",
  "feedback.label_given": "La tua valutazione per questo servizio è la seguente",
  "feedback.label_prompt": "Esprimi la tua valutazione per questo servizio",
  "provider.services.feedbacks.list.title": "Feedback Servizi",
  "generic.input_feedback_label": "Valutazione",
  "provider.feedbacks.list.title": "Valutazioni Servizi",
  "provider.feedbacks.list.subtitle": "Aggregazione delle valutazioni ottenute dai POS per i vari servizi",
  "feedback.no_feedbacks": "Nessuna valutazione",
  "pos.feedbacks.list.title": "VALUTAZIONI DEI CLIENTI",
  "pos.feedbacks.list.subtitle": "Gradimento espresso dai clienti sui tuoi servizi",
  "pos.feedbacks.title":"Elenco delle Valutazioni",
  "pos.feedbacks.subtitle":"In questa pagina trovi l’elenco dei Servizi che hai venduto con le valutazioni che i clienti hanno lasciato dopo averne usufruito.",
  "pos.feedbacks.sevice_name":"Inserisci qui il nome del servizio per cercarlo nell’elenco sottostante.",
  "pos.feedbacks.provider_name":"Seleziona il fornitore o te stesso (POS) per visualizzarne i servizi corrispondenti.",
  "pos.graphs_and_statistics.title":"ANALISI E STATISTICHE",
  "pos.graphs_and_statistics.subtitle":"Consulta i grafici relativi all’andamento delle proposte offerte ai tuoi clienti ",
  "coupons.list.tab_title": "Coupon",
  "coupons.issued.list.tab_title": "Coupon Emessi",
  "coupons.list.title": "I MIEI COUPON",
  "coupons.list.subtitle": "I Coupon che ti sono stati rilasciati come rimborso",
  "coupons.list.card.title": "Consulta i tuoi Coupon",
  "coupons.list.card.subtitle":"In questa pagina vedi l’elenco dei Coupon che hai ricevuto dal fornitore a seguito dell’annullamento di un Servizio da parte sua.<br/>Puoi utilizzare i Coupon contestualmente all’acquisto di un nuovo Servizio: il valore ti verrà scorporato dal prezzo di acquisto del servizio che sceglierai.<br/>Ricorda che puoi utilizzare i Coupon soltanto acquistando lo stesso servizio e dallo stesso fornitore dal quale ti è stato emesso.<br/>Clicca sull’icona dell’occhio per visualizzare i dettagli del Coupon corrispondente.",
  "coupons.issued.list.title": "COUPON DI RIMBORSO",
  "coupons.issued.list.subtitle": "Consulta i Coupon per rimborsare i tuoi clienti",
  "coupons.issued.list.card.title": "Consulta e crea i coupon da emettere",
  "coupons.issued.list.card.subtitle":"In questa pagina vedi l’elenco dei Coupon che hai emesso a favore dei tuoi clienti. I Coupon possono essere stati generati dal sistema come rimborso a seguito dell’annullamento di un Servizio o di un appuntamento da parte tua. <br/> Clicca sull’icona dell’occhio per visualizzare i dettagli del Coupon corrispondente.",
  "coupons.issued.list.coupon_code":"Se conosci il codice del Coupon inseriscilo qui e cercalo in questo elenco",
  "coupons.issued.list.coupon_description":"Credo che questo campo non serva",
  "coupons.issued.list.coupon_status":"Seleziona da questa tendina lo stato di utilizzo dei coupon che vuoi vedere in elenco",
  "coupons.list.empty_message": "Non hai coupon",
  "coupons.details.title": "Informazioni di dettaglio del coupon {code}",
  "coupons.details.subtitle":"Stai vedendo tutte le informazioni relative al Coupon selezionato.<br/>Puoi cliccare il codice dell’ordine per il quale il Coupon è stato emesso e accedere cosi alle informazioni di dettaglio di quell’ordine.",
  "coupons.table.code": "Codice Coupon",
  "coupons.table.description": "Descrizione",
  "coupons.table.value": "Valore",
  "coupons.table.expiration": "Scadenza",
  "coupons.table.status": "Stato Coupon",
  "coupons.detail.code": "Codice Coupon",
  "coupons.detail.description": "Descrizione",
  "coupons.detail.value": "Valore",
  "coupons.detail.expiration": "Scadenza",
  "coupons.detail.status": "Stato Coupon",
  "coupons.detail.customer": "Cliente",
  "coupons.detail.service": "Spendibile per il Servizio",
  "coupons.detail.service.all_services": "Qualunque Servizio",
  "coupons.detail.where": "Spendibile qui",
  "coupons.detail.where.everywhere": "Dovunque",
  "coupons.detail.source_order.code": "Emesso per l'ordine",
  "coupons.detail.order.code": "Utilizzato per l'ordine",
  "coupons.detail.order.not_used": "Non ancora utilizzato",
  "coupons.detail.barcode": "Scansiona il QR Code del coupon",
  "coupon.status.issue": "Erogato",
  "coupon.status.expir": "Scaduto",
  "coupon.status.redee": "Utilizzato",
  "coupon.status.tbref": "Da Rimborsare",
  "coupon.status.refun": "Rimborsato",
  "coupon.status.error": "Errore",
  "coupon.issued.list.btn_add_coupon_label": "Crea Coupon",
  "coupon.add.title": "Crea Nuovo Coupon",
  "coupon.add.subtitle": "Lorem ipsum coupon.add.subtitle",
  "coupon.add.choose_type_step.title": "Scegli il tipo di coupon da emettere",
  "coupon.type.promo": "Promozionale",
  "coupon.type.refun": "Rimborso",
  "btn_step_promo_next_label": "Successivo - Promozionale",
  "btn_step_refun_next_label": "Successivo - Rimborso",
  "coupon.details.tab_title": "Detaglio Coupon",
  "coupon.add.tab_title": "Nuovo Coupon",
  "coupon.add.choose_type_step.coupon_type_label": "Tipo Coupon",
  "coupon.add.choose_type_step.coupon_type_label.tooltip": "Scegli il tipo di coupon",
  "coupon.add.choose_type_step.coupon_description_label": "Descrizione",
  "coupon.add.choose_type_step.coupon_description_label.tooltip": "Inserisci la descrizione del coupon",
  "coupon.add.choose_type_step.coupon_expiration_label": "Scadenza",
  "coupon.add.choose_type_step.coupon_expiration_label.tooltip": "Inserisci la scadsenza del coupon",
  "coupon.add.promo_coupon_step.title": "Inserisci le informazioni di dettaglio del coupon promozionale",
  "coupon.add.refund_coupon_step.order_code.label": "Codice Ordine",
  "coupon.add.refund_coupon_step.order_code.label.tooltip": "Inserisci il codice ordine per il quale emettere il coupon",
  "coupon.add.refund_coupon_step.title": "Inserisci le informazioni di dettaglio del coupon di rimborso",
  "coupon.add.refund_coupon_step.anonymous": "Cliente Anonimo",
  "coupon.add.summary_step.refound_coupon": "Creazione coupon di rimborso ultimata",
  "coupon.add.summary_step.confirmation": "Premendo il tasto SALVA, il coupon verrà generato",
  "coupon.refund_coupon.error.order.not_valid": "Numero Ordine non valido",
  "coupon.refund_coupon.error.order.refunded": "Ordine già rimborsato",
  "coupon.refund_coupon.error.order.type_not_allowed": "Tipo ordine non consentito",

  "provider.cancel_service.btn_label": "Annulla Giornata",
  "provider.cancel_service.dialog_text": "Stai per eliminare tutti gli ordini in data {date} per il servizio {service} erogato dal Punto Viasana {pos}.",
  "provider.cancel_service.dialog_subtext": "Ai clienti registrati in Viasana che hanno acquistato servizi e al Punto Viasana verrà inviato un coupon.",
  "pos.cancel_service.btn_label": "Annulla Giornata",
  "pos.cancel_service.dialog_text": "Stai per eliminare tutti gli ordini in data {date} per il servizio {service}.",
  "pos.cancel_service.dialog_subtext": "Ai clienti registrati in Viasana che hanno acquistato servizi verrà inviato un coupon.",
  "pos.portfolio.delete_service.text": "Stai per eliminare il servizio {service} e tutti gli ordini eventualmente presenti.",
  "pos.portfolio.delete_service.subtext": "Ai clienti registrati in Viasana che hanno acquistato servizi verrà inviato un coupon.",
  "pos.portfolio.delete_service.success": "Servizio eliminato con successo",
  "pos.portfolio.delete_service.error": "Errore eliminazione servizio",
  "admin.admins.list.title": "Elenco Amministratori",
  "admin.admins.list.subtitle": "Lorem ipsum Elenco Amministratori",
  "admin.admins.detail.title": "Dettaglio Amministratore",
  "admin.admins.detail.subtitle": "Lorem ipsum Dettaglio Amministratore",
  "admin.users.list.title": "Elenco Clienti",
  "admin.users.list.subtitle": "Lorem ipsum Elenco Clienti",
  "admin.users.detail.title": "Dettaglio Cliente",
  "admin.users.detail.subtitle": "Lorem ipsum Dettaglio Cliente",
  "generic.technician": "Tecnico",
  "generic.date": "Data",
  "menu.showcase": "LA TUA VETRINA",
  "menu.buy.a.day": "AQUISTA UNA GIORNATA DI SCREENING",
  "menu.add.services": "AGGIUNGI UN TUO SERVIZIO",
  "menu.buy.for.customer": "ACQUISTA PER UN TUO CLIENTE",
  "menu.refund": "COUPON DI RIMBORSO",
  "menu.promotional": "COUPON PROMOZIONALI",
  "menu.my.coupon": "I MIEI COUPON",
  "menu.my.purchases": "I MIEI ACQUISTI",
  "menu.purchases.customers": "ACQUISTI DEI TUOI CLIENTI",
  "menu.payments": "PAGAMENTI E FATTURAZIONI",
  "menu.customer.satisfaction": "GRADIMENTO DEI CLIENTI",
  "menu.statistics": "STATISTICHE"
}
