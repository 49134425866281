import { CalendarData } from '@/model/api/CalendarData';
import { PosServiceDayPLan } from '@/model/api/PosServiceDayPlan';
import axios from 'axios';
import { HttpService } from './base/http.service';

class PosCalendarService extends HttpService {
    readonly endPoint: string = `pos`;

    public index(
        posId       : number,
        month?      : string,
        service_id? : number,
        forcustomer? : string,
        available? : boolean
    ): Promise<CalendarData[]> {
        this.source = axios.CancelToken.source();
        const cancelToken = this.source.token;

        const params = {
            month,
            service_id,
            forcustomer,
            available
        };
        
        return this.get<CalendarData[]>(
            `${this.endPoint}/${posId}/calendar`,
            { params, cancelToken }
        );
    }

    public day(
        posId      : number,
        date?      : string,
    ): Promise<PosServiceDayPLan[]> {
        this.source = axios.CancelToken.source();
        const cancelToken = this.source.token;

        const params = {
            date,
        };
        
        return this.get<PosServiceDayPLan[]>(
            `${this.endPoint}/${posId}/calendar-day`,
            { params, cancelToken }
        );
    }

    public cancelServiceDate(data: CanelServiceDateBody) {
        return this.post<{message: string, data: any}>(
            `${this.endPoint}/calendar/cancel-service-date`,
            data
        );
    }

    public fetchServices(
        posId     : number,
        available : boolean
    ) {

        this.source = axios.CancelToken.source();
        const cancelToken = this.source.token;

        return this.get<any[]>(
            `${this.endPoint}/${posId}/calendar/services`,
            {  params: { available }, cancelToken }
        );
    }

}

interface CanelServiceDateBody {
    pos_portfolio_id: number;

    /**
     * Date format: YYYY-MM-DD
     */
    date_at: string;
}

export const posCalendarService = new PosCalendarService();