import { GetterTree } from "vuex";
import { createGetters } from "@/store/common";
import { AppState } from "@/store/types";
import { CalendarState } from "./state";
import { CalendarData } from "@/model/api/CalendarData";
import moment from "moment";
import { PosServiceDayPLan } from "@/model/api/PosServiceDayPlan";

//#region Provider getters
interface ProviderGetters {
    /**
     * Month of the current monthEvents dataset
     */
    month: string;

    /**
     * Resulst of fetching events
     */
    monthEvents: CalendarData[];

    /**
     * Current month events in vue_cal format
     */
    vueCalMonthEvents: any;

    /**
     * True if is the current month 
     */
    isCurrentMonth: boolean;

    /**
     * True if param is the current month
     * @param month format YYYY-MM
     */
    getIsSameMonth(param: string): boolean;

    /**
     * Returns events for the day
     * @param day format YYYY-MM-DD
     */
    getVueCalDayEvents(day: string): any;
}

const providerGetters: GetterTree<CalendarState, AppState> = {
    ...createGetters(
        "month",
        "monthEvents"
    ),

    vueCalMonthEvents({ monthEvents }) {
        return monthEvents?.map(cd => ({
            start: cd.date_at,
            end: cd.date_at,
            title: `${cd.purchased_count}/${cd.total_count} acquistati`,
            data: {
                total: cd.total_count,
                count: cd.purchased_count,
                services: cd.services
            }
        })
        );
    },
    isCurrentMonth({ month }) {
        return moment().format("YYYY-MM") === month;
    },
    getIsSameMonth: ({ month }) => (param: string) => {
        return param === month;
    },
    getVueCalDayEvents({ monthEvents }) {
        return (day: string) => {
            const dayEvents = monthEvents?.find(e => e.date_at === day);

            return dayEvents?.services
                ?.map(s => ({
                    start: new Date(`${day}T${s.service_time_from}`),
                    end: new Date(`${day}T${s.service_time_to}`),
                    title: s.service_name,
                    content: s.order
                        ? `
                            <p>Acquistato da:</p>
                            <p>
                                ${s.order.pos.business_name}
                            </p>
                            <p>Tecnico:</p>
                            <p>
                                ${s.order.technician.name} 
                                ${s.order.technician.surname}
                            </p>
                        `
                        : '<p>Slot libero</p>',
                    class: s.order
                        ? 'taken'
                        : 'empty',
                    serviceId: s.service_id,
                    data: s
                }));
        }
    }
}
//#endregion 

//#region POS getters
interface POSGetters {
    /**
     * Month of the current monthEvents dataset
     */
    posMonth: string;

    /**
     * Resulst of fetching events
     */
    posMonthEvents: CalendarData[];

    /**
     * Current month events in vue_cal format
     */
    vueCalPosMonthEvents: any;

    /**
     * True if is the current month 
     */
    isCurrentPosMonth: boolean;

    /**
     * True if param is the current month
     * @param month format YYYY-MM
     */
    getIsSamePosMonth(param: string): boolean;

    /**
     * Returns events for the day
     * @param day format YYYY-MM-DD
     */
    getPosVueCalDayEvents(day: string): any;

    posDate: string;
    posDayEvents: PosServiceDayPLan[];
}

const posGetters: GetterTree<CalendarState, AppState> = {
    ...createGetters(
        "posMonth",
        "posMonthEvents",
        "posDate",
        "posDayEvents"
    ),

    vueCalPosMonthEvents({ posMonthEvents }) {
        return posMonthEvents?.map((pe: any) => {
            return pe.portfolio.map(p => {
                return {
                    start: pe.date_at,
                    end: pe.date_at,

                    title: `${p.service?.name}`,

                    data: {
                        total: p.total_slots_count,
                        count: p.available_slots_count,
                        value: p
                    }
                }
            });
        }).flat();
    },

    isCurrentPosMonth({ posMonth }) {
        return moment().format("YYYY-MM") === posMonth;
    },
    getIsSamePosMonth: ({ posMonth }) => (param: string) => {
        return param === posMonth;
    },
    getPosVueCalDayEvents({ posMonthEvents }) {
        return (day: string) => {
            const dayEvents = posMonthEvents?.find(e => e.date_at === day);

            return dayEvents?.services
                ?.map(s => ({
                    start: new Date(`${day}T${s.service_time_from}`),
                    end: new Date(`${day}T${s.service_time_to}`),
                    title: s.service_name,
                    content: s.order
                        ? `
                            <p>Acquistato da:</p>
                            <p>
                                ${s.order.pos.business_name}
                            </p>
                            <p>Tecnico:</p>
                            <p>
                                ${s.order.technician.name} 
                                ${s.order.technician.surname}
                            </p>
                        `
                        : '<p>Slot libero</p>',
                    class: s.order
                        ? 'taken'
                        : 'empty',
                    serviceId: s.service_id,
                    data: s
                }));
        }
    }
}
//#endregion 

export interface Getters
    extends ProviderGetters, POSGetters {
}

const getters: GetterTree<CalendarState, AppState> = {
    ...providerGetters,
    ...posGetters
}

export default getters;