import { isMobile } from '@/utils/utils';
import { RouteRecordRaw } from 'vue-router';
import { AuthRoutesEnum } from './AuthRoutesEnum';

const LoginMobilePage         = () => import(
    /* webpackChunkName: "auth-module-mobile" */ 
    '../pages/LoginMobile/LoginMobilePage.vue'
);

const LoginPage         = () => import(
    /* webpackChunkName: "auth-module" */ 
    '../pages/Login/LoginPage.vue'
);
const LostPasswordPage  = () => import(
    /* webpackChunkName: "auth-module" */ 
    '../pages/LostPassword/LostPasswordPage.vue'
);
const ResetPasswordPage = () => import(
    /* webpackChunkName: "auth-module" */ 
    '../pages/ResetPassword/ResetPasswordPage.vue'
);

const routes: Array<RouteRecordRaw> = isMobile()
    ? /* Mobile routes */ [
        {
            path      : '/signin',
            alias     : ['/login', '/sign-in'],
            name      : AuthRoutesEnum.SIGN_IN,
            component : LoginMobilePage,
            meta      : {
                tabTitle      : 'login.title',
                requiresGuest : true
            }
        },
        { //TODO:
            path      : '/forgot-password',
            name      : AuthRoutesEnum.FORGOTTEN_PWD,
            component : LostPasswordPage,
            meta      : {
                tabTitle      : 'reset_password.title',
                requiresGuest : true
            }
        },
        {
            path      : '/reset-password/:token',
            name      : AuthRoutesEnum.RESET_PWD,
            component : ResetPasswordPage,
            props     : true,
            meta      : {
                tabTitle      : 'reset_password.title',
                requiresGuest : true
            }
        }
    ]
    : /* Desktop routes */ [
        {
            path      : '/signin',
            alias     : ['/login', '/sign-in'],
            name      : AuthRoutesEnum.SIGN_IN,
            component : LoginPage,
            meta      : {
                tabTitle      : 'login.title',
                requiresGuest : true
            }
        },
        {
            path      : '/forgot-password',
            name      : AuthRoutesEnum.FORGOTTEN_PWD,
            component : LostPasswordPage,
            meta      : {
                tabTitle      : 'reset_password.title',
                requiresGuest : true
            }
        },
        {
            path      : '/reset-password/:token',
            name      : AuthRoutesEnum.RESET_PWD,
            component : ResetPasswordPage,
            props     : true,
            meta      : {
                tabTitle      : 'reset_password.title',
                requiresGuest : true
            }
        }
    ];

export { routes };
export { AuthRoutesEnum };