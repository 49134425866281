import { RouteRecordRaw } from 'vue-router';
import { CalendarRoutesEnum } from './CalendarRoutesEnum';

const CalendarPage = () => import(
    /* webpackChunkName: "Calendar-module" */
    '../pages/calendar/CalendarPage.vue'
);

const CalendarDatePage = () => import(
    /* webpackChunkName: "Calendar-module" */
    '../pages/calendar-date/CalendarDatePage.vue'
);

const PlannedServicePage = () => import(
    /* webpackChunkName: "Calendar-module" */
    '../pages/planned-service/PlannedServicePage.vue'
);


const PosCalendarPage = () => import(
    /* webpackChunkName: "Pos-Calendar-module" */
    '../pages/pos-calendar/PosCalendarPage.vue'
);

const PosCalendarDatePage = () => import(
    /* webpackChunkName: "Pos-Calendar-module" */
    '../pages/pos-calendar-date/PosCalendarDatePage.vue'
);

const routes: Array<RouteRecordRaw> = [
    // ADMIN / PROVIDER
    {
        path      : '/calendar',
        name      : CalendarRoutesEnum.CALENDAR,
        component : CalendarPage,
        meta      : {
            tabTitle : "calendar.month_view.tab_title"
        }
    },
    {
        path      : '/calendar/:day',
        props     : true,
        name      : CalendarRoutesEnum.CALENDAR_DATE,
        component : CalendarDatePage,
        meta      : {
            tabTitle : "calendar.day_view.tab_title"
        }
    },
    {
        path      : '/calendar/planned-service',
        props     : (route) => ({   
            service: route.params.service 
                ? JSON.parse(route.params.service as string)
                : null,
        }),
        name      : CalendarRoutesEnum.CALENDAR_SERVICE,
        component : PlannedServicePage,
        meta      : {
            tabTitle : "calendar.day_view.tab_title"
        }
    },

    // POS
    {
        path      : '/pos-calendar/:forcustomer',
        props     : true,
        name      : CalendarRoutesEnum.POS_CALENDAR,
        component : PosCalendarPage,
        meta      : {
            tabTitle : "calendar.month_view.tab_title"
        }
    },
    {
        path      : '/pos-calendar/:day/:forcustomer',
        props     : true,
        name      : CalendarRoutesEnum.POS_CALENDAR_DATE,
        component : PosCalendarDatePage,
        meta      : {
            tabTitle : "calendar.day_view.tab_title"
        }
    },
];

export { routes };
export { CalendarRoutesEnum };