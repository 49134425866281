import { User } from "@/model/api/User";

export interface AuthState {
    me: User;
    appProfile: any;
}

const state: AuthState = {
    me: null,
    appProfile: null,
};

export default state;