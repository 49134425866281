import { MutationTree } from 'vuex';
import { User } from '@/model/api/User';
import { AuthState } from './state';

export interface Mutations {
    setMe(user: User): void;
    setAppProfile(appProfile): void;
}

const mutations: MutationTree<AuthState> = {
    setMe(state, user: User) {
        state.me = user;
    },
    setAppProfile(state, appProfile) {
        state.appProfile = appProfile;
    },
}

export default mutations;