import { Appointment } from '@/model/api/appointment';
import { Provider, ProviderTechnician } from '@/model/api/Provider';
import axios, { CancelTokenSource } from 'axios';
import { CRUD } from './base/crud';

class ProviderTechniciansService extends CRUD<ProviderTechnician, any> {
    readonly endPoint = `provider-technicians`;

    getByEmailSource: CancelTokenSource = null;

    public getByEmail(email: string) {
        this.getByEmailSource?.cancel();
        this.getByEmailSource = axios.CancelToken.source();        
        
        return this.get<ProviderTechnician>(`${this.endPoint}/by-email`, {
            params: { email },
            cancelToken: this.getByEmailSource.token
        });
    }

    public getByEmailProvider(email: string, provider_id: number) {
        this.getByEmailSource?.cancel();
        this.getByEmailSource = axios.CancelToken.source();        
        
        return this.get<ProviderTechnician>(`${this.endPoint}/by-email-provider`, {
            params: { email, provider_id },
            cancelToken: this.getByEmailSource.token
        });
    }

    public associate(technician_id: number, provider_id: number, is_active: boolean) {
        return this.post<void>(`${this.endPoint}/associate`, {
            technician_id,
            provider_id,
            is_active
        });
    }

    public dissociate(technician_id: number, provider_id: number) {
        return this.post<void>(`${this.endPoint}/dissociate`, {
            technician_id,
            provider_id
        });
    }

    public getByIdForEntityId(id: number, entity_id: number) {
        return this.get<ProviderTechnician>(`${this.endPoint}/${id}`, {
            params: { entity_id }
        });
    }

    public appointments(id: number ,provider_id: number, month: string){
        return this.get<Appointment[]>(`${this.endPoint}/${id}/appointments`, {
            params: { provider_id, month }
        })
    }

    public getProfile(id: number){
        return this.get<ProviderTechnician>(`${this.endPoint}/${id}/profile`)
    }

    public updateAppProfile(id: number, data) {
        return this.post<ProviderTechnician>(
            `${this.endPoint}/${id}/profile`,
            data
        )
    }

    public providers(technicianId: number){
        return this.get<Provider[]>(`${this.endPoint}/${technicianId}/providers`)
    }

    public services(technicianId: number) {
        return this.get<any[]>(
            `${this.endPoint}/${technicianId}/my-services`,
        )
    }

    public toggleService(technicianId: number, serviceData: ToggleServiceData) {
        return this.post<any>(
            `${this.endPoint}/${technicianId}/toggle-my-service`,
            serviceData
        )
    }
    
    public compatibleServices(technicianId: number, params?) {
        return this.get<any>(
            `${this.endPoint}/${technicianId}/compatible-services`,
            { params }
        )
    }

    public availabilities(id: number, provider_id: number, month: string){
        return this.get<any[]>(`${this.endPoint}/${id}/availabilities`, 
            { params: { provider_id, month } }
        )
    }
    
    public toggleAvailability(id: number, data: ToggleAvailabilityData){
        return this.post<any[]>(
            `${this.endPoint}/${id}/toggle-availability`, 
            data
        )
    }
}

export interface ToggleServiceData {
    service_id: number;
    provider_id: number;
}

export interface ToggleAvailabilityData {
    date_at: string;
    provider_id: number;
}

export interface CompatibleServicesParams {
    provider_id: number; 
}

export const providerTechniciansService = new ProviderTechniciansService();