
import moment from 'moment-timezone'
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({})
export default class DateFormatted extends Vue {
  @Prop() readonly value!: any;

  @Prop()
  readonly format!: string;

  get formattedDate() {
    return this.value ? moment(this.value).format(this.format) : '-';
  }
}
