import { Plugin } from 'vue';

import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";
import { useI18n } from 'vue-i18n';

/*
    TODO: I18n messaggi default 
*/

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        /**
         * Success message.   
         * In generally a green toast
         */
        readonly $successMessage: (detail: string, summary?: string) => void;
        /**
         * Error message.  
         * In generally a red toast 
         */
        readonly $errorMessage: (detail: string, summary?: string) => void;


        readonly $confirmMessage: (detail: string, summary?: string) => Promise<boolean>;
    }
}

const MessagesPlugin: Plugin = {
    install(app) {
        console.debug("Installing messages plugin...");

        app.config.globalProperties.$successMessage =
            function (detail: string, summary: string = null) {
                this.$toast.add({
                    severity: 'success',
                    summary: summary || this.$t('generic_success'),
                    detail,
                    life: 3000
                });
            };

        app.config.globalProperties.$errorMessage =
            function (detail: string, summary: string = null) {
                this.$toast.add({
                    severity: 'error',
                    summary: summary || this.$t('generic_attention'),
                    detail,
                    life: 3000
                });
            }


        app.config.globalProperties.$confirmMessage =
            function (message: string, header: string = null) {
                return new Promise((resolve, reject) => {

                    this.$confirm.require({
                        message,
                        header: header || this.$t('generic_attention'),
                        icon: 'pi pi-exclamation-triangle',
                        acceptClass: 'p-button-danger',
                        acceptLabel: this.$t("yes_label"),
                        rejectLabel: this.$t("no_label"),
                        accept: () => {
                            resolve(true);
                        },
                        reject: () => {
                            resolve(false);
                        }
                    });

                });

            }
    }
};

export default MessagesPlugin;

export function useMessage() {
    const {
        t
    } = useI18n();

    const {
        add
    } = useToast();

    const {
        require
    } = useConfirm();


    function successMessage(
        detail: string,
        summary: string = t('generic_success')
    ) {
        add({
            severity: 'success',
            summary,
            detail,
            life: 3000
        });
    }

    function errorMessage(
        detail: string,
        summary: string = t('generic_attention')
    ) {
        add({
            severity: 'error',
            summary,
            detail,
            life: 3000
        });
    }

    function errorMessageLeft(
        detail: string,
        summary: string = "Aborted"
    ) {
        add({
            severity: 'error',
            summary,
            detail,
            life: 3000,
            group: 'top-left'
        });
    }

    function confirmMessage(
        message: string,
        header: string = "Confirmation requested"
    ) {
        return new Promise(
            (resolve) => {
                require({
                    message, header,
                    icon: 'pi pi-exclamation-triangle',
                    acceptClass: 'p-button-danger',
                    accept: () => {
                        resolve(true);
                    },
                    reject: () => {
                        resolve(false);
                    }
                });
            }
        );
    }

    return {
        successMessage,
        errorMessage,
        errorMessageLeft,
        confirmMessage
    }
}
