import { RouteRecordRaw } from 'vue-router';
import { ServicesRoutesEnum } from './ServicesRoutesEnum';
import { routes as providerRoutes } from './providerPortfolioServices';

const ServiceListPage = () => import(/* webpackChunkName: "Services-module" */ '../pages/ServiceListPage.vue');
const ServiceEditPage = () => import(/* webpackChunkName: "Services-module" */ '../pages/ServiceEditPage.vue');
const ServiceVisibilityPage = () => import(/* webpackChunkName: "Services-module" */ '../pages/ServiceVisibilityPage.vue');

const NetworkListPage = () => import(/* webpackChunkName: "Services-module" */ '../pages/NetworkListPage.vue');
const NetworkEditPage = () => import(/* webpackChunkName: "Services-module" */ '../pages/NetworkEditPage.vue');

const SponsorListPage = () => import(/* webpackChunkName: "Services-module" */ '../pages/SponsorListPage.vue');
const SponsorEditPage = () => import(/* webpackChunkName: "Services-module" */ '../pages/SponsorEditPage.vue');

const CategoryListPage = () => import(/* webpackChunkName: "Services-module" */ '../pages/CategoryListPage.vue');
const CategoryEditPage = () => import(/* webpackChunkName: "Services-module" */ '../pages/CategoryEditPage.vue');

const routes: Array<RouteRecordRaw> = [
	...providerRoutes,

	{
		path: '/elenco-servizi',
		name: ServicesRoutesEnum.SERVICES_LIST,
		props: true,
		component: ServiceListPage,
		meta: {
			tabTitle: "admin.services.list.title"
		}
	},
	{
		path: '/dettaglio-servizio/:serviceId',
		name: ServicesRoutesEnum.SERVICES_EDIT,
		props: true,
		component: ServiceEditPage,
		meta: {
			tabTitle: "admin.services.detail.title"
		}
	},
	{
		path: '/dettaglio-servizio-visibilita/:serviceId',
		name: ServicesRoutesEnum.SERVICES_VISIBILITY,
		props: true,
		component: ServiceVisibilityPage,
		meta: {
			tabTitle: "admin.services.visibility.title"
		}
	},

	{
		path: '/network',
		name: ServicesRoutesEnum.NETWORK_LIST,
		props: true,
		component: NetworkListPage,
		meta: {
			tabTitle: "admin.networks.list.title"
		}
	},
	{
		path: '/dettaglio-network/:networkId',
		name: ServicesRoutesEnum.NETWORK_EDIT,
		props: true,
		component: NetworkEditPage,
		meta: {
			tabTitle: "admin.networks.detail.title"
		}
	},

	{
		path: '/sponsor',
		name: ServicesRoutesEnum.SPONSORS_LIST,
		props: true,
		component: SponsorListPage,
		meta: {
			tabTitle: "admin.sponsors.list.title"
		}
	},
	{
		path: '/dettaglio-sponsor/:sponsorId',
		name: ServicesRoutesEnum.SPONSORS_EDIT,
		props: true,
		component: SponsorEditPage,
		meta: {
			tabTitle: "admin.sponsors.detail.title"
		}
	},

	{
		path: '/categorie-servizi',
		name: ServicesRoutesEnum.CATEGORIES_LIST,
		props: true,
		component: CategoryListPage,
		meta: {
			tabTitle: "admin.service_categories.list.title"
		}
	},
	{
		path: '/dettaglio-categoria-servizi/:categoryId',
		name: ServicesRoutesEnum.CATEGORIES_EDIT,
		props: true,
		component: CategoryEditPage,
		meta: {
			tabTitle: "admin.service_categories.detail.title"
		}
	},
];

export { routes };
export { ServicesRoutesEnum };