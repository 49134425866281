/**
 * NB:
 * È fondamentale che ciascun gruppo di rotte sia identificabile dal prefisso.
 * 
 * Es: le rotte degli utenti devono iniziare con "users",
 * sopratutto se si raggiungono tramite la tabella utenti.
 * Altrimenti al rientro su di essa vengono cancellati i filtri
 */
export enum POSRoutesEnum {
    PREFIX = "pos",
    LIST = 'pos.list',
    EDIT = 'pos.edit',

    CATEGORIES_PREFIX = "pos-categories",
    CATEGORIES_LIST = 'pos-categories.list',
    CATEGORIES_EDIT = 'pos-categories.edit',
    
    POS_ORDERS = 'pos-orders',
    POS_CUSTOMER_ORDERS = 'pos-customer-orders',
    POS_CUSTOMER_FEEDBACKS = 'pos-customer-feedbacks',
    PROFILE_AREA = 'pos.profile-area',

    ADD_SERVICE_SELECT = 'pos-services-add-select-type',
    ADD_SERVICE = 'pos.services.add',
    EDIT_SERVICE = 'pos.services.edit',
    SERVICES_LIST = 'pos.services.list',

    PORTFOLIO_LIST = 'pos.portfolio.list',
    PORTFOLIO_EDIT = 'pos.portfolio.edit',
    ADD_PROVIDER_SERVICE = 'pos.portfolio.addProviderService',
    ADD_SINGLE_SERVICE = 'pos.portfolio.addSingleService',
    ADD_RECURSIVE_SERVICE = 'pos.portfolio.addRecursiveService',

    POS_GRAPHS = 'pos-graphs',
    INVOICES = 'invoices',
}

export enum POSAddProviderServiceStepEnum {
    CHOOSE_SERVICE = 'choose-service',
    CHOOSE_PROVIDER = 'choose-provider',
    PROVIDER_CALENDAR = 'provider-calendar',
    CHOOSE_COUPON = 'choose-coupon',
    PROVIDER_SUMMARY = 'provider-summary',
    PAYMENT = 'payment',
    PAYMENT_RESULT = 'payment-result',
    CONFIG = 'config'
}

export enum POSAddServiceStepEnum {
    CHOOSE_SERVICE = 'choose-service',
    ATTACHMENTS = 'attachments',
    SUMMARY = 'summary'
}

export enum POSAddSingleServiceStepEnum {
    CHOOSE_SERVICE = 'choose-service',
    ATTACHMENTS = 'attachments',
    CALENDAR = 'calendar',
    CONFIG = 'config'
}

export enum POSAddRecursiveServiceStepEnum {
    CHOOSE_SERVICE = 'choose-service',
    ATTACHMENTS = 'attachments',
    CALENDAR = 'calendar',
    TIME_SLOTS = 'time-slots',
    CONFIG = 'config'
}

export enum ServicePathsEnum {
    STANDARD = 'standard',
    SINGLE_SERVICE = 'single',
    RECURRING_SERVICE = 'recurring',
    PROVIDER = 'provider'
}