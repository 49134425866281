/**
 * NB:
 * È fondamentale che ciascun gruppo di rotte sia identificabile dal prefisso.
 * 
 * Es: le rotte degli utenti devono iniziare con "users",
 * sopratutto se si raggiungono tramite la tabella utenti.
 * Altrimenti al rientro su di essa vengono cancellati i filtri
 */
export enum AdminStatsRoutesEnum {
    
    MAIN = 'adminstats.main',

    ADMS_POS_NEWSERVICES = "adms-pos-newservices",
    ADMS_POS_GEOBUYEDSERVICES = "adms-pos-geoservicesbuyed",
    ADMS_CUSTOMER_GEOBUYEDSERVICES = "adms-customer-geoservicesbuyed",

}