import { CalendarData } from '@/model/api/CalendarData';
import { ProviderTechnician } from '@/model/api/Provider';
import axios from 'axios';
import { HttpService } from './base/http.service';

class ProviderCalendarService extends HttpService {
    readonly endPoint: string = `providers`;

    public index(
        providerId     : number,
        month?         : string,
        service_id?    : number,
        technician_id? : number
    ): Promise<CalendarData[]> {
        this.source = axios.CancelToken.source();
        const cancelToken = this.source.token;

        const params = {
            month,
            service_id,
            technician_id
        };
        
        return this.get<CalendarData[]>(
            `${this.endPoint}/${providerId}/calendar`,
            { params, cancelToken }
        );
    }

    public fetchTechnicians(
        providerId     : number,
        service_id?    : number,
    ) {

        this.source = axios.CancelToken.source();
        const cancelToken = this.source.token;

        return this.get<ProviderTechnician[]>(
            `${this.endPoint}/${providerId}/calendar/technicians`,
            { params: { service_id }, cancelToken }
        );
    }

    public fetchServices(
        providerId     : number,
        technician_id?    : number,
    ) {

        this.source = axios.CancelToken.source();
        const cancelToken = this.source.token;

        return this.get<any[]>(
            `${this.endPoint}/${providerId}/calendar/services`,
            { params: { technician_id }, cancelToken }
        );
    }

    public cancelServiceDate(order_id: number) {
        return this.post<any>(`${this.endPoint}/calendar/cancel-service-date`, {order_id});
    }
}

interface CanelServiceDateBody {
    pos_portfolio_id: number;

    /**
     * Date format: YYYY-MM-DD
     */
    date_at: string;
}

export const providerCalendarService = new ProviderCalendarService();