export enum CouponsRoutesEnum {
    COUPONS = 'coupons',
    ISSUED_COUPONS = 'issued-coupons',

    COUPON_DETAILS = 'coupon-details',
    ADD_COUPON = 'add-coupon'
}

export enum AddCouponStepEnum {
    CHOOSE_TYPE = 'choose-type',
    PROMO_COUPON = 'promo-coupon',
    REFUND_COUPON = 'refund-coupon',
    SUMMARY = 'summary',
}