export { };

declare global {
    interface Array<T> {
        /**
         * Group by function distinct 
         * @param keyFn the string returned will be key of a group
         */
        groupBy(keyFn: (item: T) => string): Map<string, T[]>;
        /**
         * Group by key (property)
         * @param key key of a group
         */
        groupBy(key: keyof T): Map<string, T[]>;
    }
}

if (!Array.prototype.groupBy) {
    Array.prototype.groupBy = function <T>(key: ((x: any) => string) | keyof T) {
        
        if (typeof key === 'function') {
            return this.reduce(function (map: Map<string, T[]>, x) {
                const k = key(x);

                const o = map.get(k) || [];

                map.set(k, [...o, x]);

                return map;
            }, new Map<string, T[]>());
        }

        if (typeof key === 'string') {
            return this.reduce(function (map: Map<string, T[]>, x) {
                const k =  x[key];
                
                const o = map.get(k) || [];

                map.set(k, [...o, x]);

                return map;
            }, new Map<string, T[]>());
        }

    }
}
