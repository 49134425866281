import { defineRule, configure } from 'vee-validate';
import { localize } from '@vee-validate/i18n';
import en from '@vee-validate/i18n/dist/locale/en.json';
import it from '@vee-validate/i18n/dist/locale/it.json';

import {
  required,
  email,
  min,
  max,
  confirmed,
  regex,
  min_value
} from '@vee-validate/rules';

defineRule('required', (x: any) => {
  return (required(x));
});

defineRule('email', (x: any) => {
  return (email(x));
});

defineRule('min', (x: string, y: any) => {
  return (min(x, y));
});

defineRule('max', (x: string, y: any) => {
  return (max(x, y));
});

defineRule('confirmed', (x: string, y: any) => {
  return (confirmed(x, y));
});

defineRule('passwordRegex', (value: string) => {
  return (regex(value, [/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/]));
});

defineRule('min_value', (x: string, y: any) => {
  return (min_value(x, y));
});

configure({
  generateMessage: localize({
    en,
    it,
  }),
});
// Object.keys(rules).forEach(rule => {
//   defineRule(rule, AllRules[rule]);
// });